import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import { BlockSpray } from '../../../../../models/userTypes';
import {
  Action,
  deleteUpdateDispatch,
  State
} from '../utils/sprayPlanCardReducer';
import SprayPlanCardInput from './SprayPlanCardInput';
import SprayPlanCardProductSelect from './SprayPlanCardProductSelect';

interface Props {
  spray: BlockSpray;
  state: State;
  dispatch: React.Dispatch<Action>;
}

const SprayPlanCardForm = ({ spray, state, dispatch }: Props) => {
  return (
    <>
      <Row className='mb-3'>
        <Col>
          <SprayPlanCardProductSelect
            spray={spray}
            property={'licence_no'}
            label={'Product'}
            message='The spray product'
            state={state}
            dispatch={dispatch}
          />
        </Col>
        <Col>
          <SprayPlanCardInput
            spray={spray}
            property={'applications'}
            label={'Applications'}
            value={spray.applications}
            message={'The amount per season'}
            state={state}
            dispatch={dispatch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SprayPlanCardInput
            spray={spray}
            property={'target'}
            label={'Target'}
            value={spray.target}
            message='The spray target'
            state={state}
            dispatch={dispatch}
          />
        </Col>
        <Col>
          <SprayPlanCardInput
            spray={spray}
            property={'timing'}
            label={'Timing'}
            value={spray.timing}
            message='The approximate timing'
            state={state}
            dispatch={dispatch}
          />
        </Col>
      </Row>
      <Row className='mb-0 mt-2'>
        <Col xs={5} sm={5} md={4}>
          <Row>
            <Col xs={3} sm={3} md={2}>
              <input
                id='delete-product-checkbox'
                className='mt-1'
                type='checkbox'
                style={{ height: '1.5em', width: '1.5em' }}
                onChange={() => {
                  deleteUpdateDispatch(state, dispatch, spray);
                }}
              />
            </Col>
            <Col>
              <label
                htmlFor='delete-product-checkbox'
                className='mt-1 text-danger'
              >
                Delete Product?
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr></hr>
    </>
  );
};

export default observer(SprayPlanCardForm);
