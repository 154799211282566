import { Field, ErrorMessage } from 'formik';
import { Form as rbForm, FormLabel } from 'react-bootstrap';

interface Props {
  label: string;
  subLabel: string;
  fieldName: string;
  fieldAs?: string;
  errorName: string;
  children: JSX.Element;
  required?: boolean;
}

const ModalSelectFormField = ({
  label,
  subLabel,
  fieldName,
  fieldAs,
  errorName,
  children,
  required = false
}: Props): JSX.Element => {
  return (
    <rbForm.Group>
      <FormLabel>
        <b>{label}</b>
        {required ? <i className='ml-3'>(required)</i> : null}
        <rbForm.Text style={{ fontSize: '14px' }}>{subLabel}</rbForm.Text>
      </FormLabel>
      {fieldAs ? (
        <Field name={fieldName} as={fieldAs} className='form-control' required>
          {children}
        </Field>
      ) : (
        children
      )}
      <ErrorMessage name={errorName} />
    </rbForm.Group>
  );
};

export default ModalSelectFormField;
