import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { BasicSprayProduct } from '../../models/propertyTypes';
import { PropertyBlock } from '../../models/userTypes';
import { UserPropertyContext } from '../../stores/StoreContexts';
import ProductSelect from '../helpers/forms/ProductSelect';
import { updateProductsChecked } from '../risk-assessment/RiskAssessmentProducts/utils';
import {
  Action,
  ActionKind,
  ProductChecked
} from '../risk-assessment/utils/riskAssessmentReducer';

interface Props {
  products: ProductChecked[];
  productionArea: PropertyBlock | null;
  dispatch: React.Dispatch<Action>;
}

const RiskAssessmentSelectProducts = ({
  products,
  productionArea,
  dispatch
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [show, setShow] = useState(false);
  const [productsChecked, setProductsChecked] =
    useState<ProductChecked[]>(products);
  const allProducts = useRef<(BasicSprayProduct | undefined)[]>([]);

  useEffect(() => {
    if (userPropertyStore.products && allProducts.current.length === 0) {
      const existingProducts = productionArea?.block_sprays || [];
      const existingBasicProducts = existingProducts.map((product) => {
        const basicProduct = userPropertyStore.products?.find(
          (p) => p.licence_no === product.licence_no
        );
        return basicProduct;
      });
      allProducts.current = Array.from(
        new Set([
          ...existingBasicProducts,
          ...(products.map((pc) => pc.product) || [])
        ])
      );
      if (products.length === 0) {
        const productChecked: ProductChecked[] = [];
        allProducts.current.forEach((product) => {
          const newProductChecked = {
            product: product as BasicSprayProduct,
            checked: false
          };
          productChecked.push(newProductChecked);
        });
        dispatch({
          type: ActionKind.SET_PRODUCT,
          payload: productChecked
        });
        setProductsChecked(() => productChecked);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPropertyStore.products, dispatch, productionArea?.block_sprays]);

  return (
    <>
      <Button
        variant='outline-success'
        onClick={() => {
          setShow(true);
        }}
      >
        Select Products
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop='static'
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            What's In The Tank
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='primary'>
            Select from the products listed below that are already associated
            with this production area, or add new products by searching.
          </Alert>
          <ProductSelect
            onChange={(v) => {
              if (v && v.value) {
                const product = userPropertyStore.products?.find(
                  (product) => product.licence_no === v.value
                );
                setProductsChecked([
                  ...productsChecked,
                  { product, checked: true }
                ]);
              }
            }}
          />
          {productsChecked.map((productChecked, i) => {
            const product = productChecked.product;
            const isSelected =
              productsChecked.find(
                (productChecked) =>
                  productChecked.product?.id === product?.id &&
                  productChecked.checked
              ) !== undefined;
            return (
              <Row key={i} className='mt-2'>
                <Col className='m-1'>{product?.name}</Col>
                <Col>
                  <input
                    type='checkbox'
                    checked={isSelected}
                    onChange={() => {
                      if (isSelected) {
                        const newSelectedProducts = updateProductsChecked(
                          productsChecked,
                          product,
                          false
                        );
                        setProductsChecked(newSelectedProducts);
                      } else {
                        const newSelectedProducts = updateProductsChecked(
                          productsChecked,
                          product,
                          true
                        );
                        setProductsChecked(newSelectedProducts);
                      }
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='float-right'
            variant='outline-secondary'
            onClick={() => {
              setProductsChecked(products);
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className='float-right'
            variant='success'
            onClick={() => {
              dispatch({
                type: ActionKind.SET_PRODUCT,
                payload: productsChecked
              });
              setShow(false);
            }}
          >
            <div className='px-4'>Save &amp; Close</div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(observer(RiskAssessmentSelectProducts));
