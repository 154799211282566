import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { UserProperty } from '../../../models/userTypes';
import PropertyStore from '../../../stores/PropertyStore';

type Props = {
  userPropertyStore: PropertyStore;
  details?: UserProperty;
};

const SprayPlanReportButton = ({
  userPropertyStore,
  details
}: Props): JSX.Element => {
  const history = useHistory();
  const isSprayPlanReady = (details: UserProperty | undefined) => {
    return details
      ? userPropertyStore.isSprayPlanReady(details.id)
      : userPropertyStore.isSprayPlanReady();
  };

  return (
    <Button
      variant='success'
      disabled={!isSprayPlanReady(details)}
      onClick={() => {
        history.push(`/sprayplan-report/${details?.id}`);
        if (details && details.id) {
          userPropertyStore.setSelectedProperty(details.id);
          userPropertyStore.getProductionAreaDetails(
            userPropertyStore.selectedProperty?.id
          );
        }
      }}
      style={
        isSprayPlanReady(details)
          ? {}
          : { cursor: 'not-allowed', marginBottom: '0.75em' }
      }
    >
      <FontAwesomeIcon icon={faClipboard} className='mr-1' /> SprayPlan Report
    </Button>
  );
};

export default observer(SprayPlanReportButton);
