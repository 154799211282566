import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { State } from '../risk-assessment/utils/riskAssessmentReducer';
import ReportDeclaration from './ReportDeclaration/ReportDeclaration';
import ReportForecastImpact from './ReportForecastImpact/ReportForecastImpact';
import ReportHazards from './ReportHazards/ReportHazards';
import ReportProductRisk from './ReportProductRisk/ReportProductRisk';
import ReportProperty from './ReportProperty/ReportProperty';
import ReportSensitiveAreas from './ReportSensitiveAreas/ReportSensitiveAreas';
import './risk-assessment-report.scss';

const RiskAssessmentReport = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const reportRef = useRef<HTMLDivElement | null>(null);
  const reportData: State | undefined = useMemo(() => {
    const dataString = localStorage.getItem('risk-assessment');
    if (dataString) return JSON.parse(dataString) as State;
  }, []);

  // Report data properties.
  const property = reportData?.property;
  const productionArea = reportData?.productionArea;
  const sprayDate =
    reportData?.sprayDate &&
    new Date(reportData?.sprayDate).toLocaleDateString();
  const sprayTime = reportData?.sprayTime;
  const forecastImpact = reportData?.forecastImpact;
  const products = useMemo(
    () =>
      reportData?.products
        .map((pc) => (pc.checked ? pc.product : null))
        .filter((prod) => prod !== null),
    [reportData?.products]
  );
  const declaration = reportData?.declaration;

  useData(property?.id);

  useEffect(() => {
    if (productionArea) {
      userPropertyStore.fetchHsnoData(products);
    }
  }, [
    products,
    property?.id,
    productionArea?.id,
    userPropertyStore,
    productionArea
  ]);

  return (
    <>
      {userPropertyStore.loading || userPropertyStore.loadingHsnoData ? (
        <Container className='px-3 py-2'>
          <Row className='mt-3'>
            <Col className='text-center'>
              <h4>Generating Report...</h4>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className='text-center'>
              <Spinner variant='primary' animation={'border'} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className='px-3 py-2'>
          <div ref={reportRef} className='px-3 py-3'>
            <style type='text/css' media='print'>
              {'\
              @page { size: landscape; }\
            '}
            </style>
            {!reportData ? (
              <div>No data found to generate the report</div>
            ) : (
              <div>
                <Row className='mb-4'>
                  <Col>
                    <ReportProperty
                      property={property}
                      productionArea={productionArea}
                      sprayDate={sprayDate}
                      sprayTime={sprayTime}
                      reportRef={reportRef}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    <ReportForecastImpact forecastImpact={forecastImpact} />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    <ReportSensitiveAreas />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    <ReportProductRisk />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    <ReportHazards productionArea={productionArea} />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col>
                    <ReportDeclaration declaration={declaration} />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default observer(RiskAssessmentReport);
