import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import Heading from './Heading';
import HTTable from './tables/HTTable';

const HumanToxicity = (): JSX.Element => {
  return (
    <div className='mb-4'>
      <Row>
        <Col>
          <Heading>HSNO Class 6 - Human Toxicity</Heading>
          <HTTable />
        </Col>
      </Row>
    </div>
  );
};

export default observer(HumanToxicity);
