import { useState, useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';

interface Props {
  variant: string;
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const LoadingButton = (props: Props): JSX.Element => {
  const { variant, text, onClick, style } = props;
  const userPropertyStore = useContext(UserPropertyContext);
  const [isLoading, setLoading] = useState(userPropertyStore.loading);

  const handleClick = () => setLoading(true);

  return (
    <Button
      style={{ minWidth: '80px', ...style }}
      variant={variant}
      disabled={isLoading}
      onClick={() => {
        if (!isLoading) {
          onClick();
          handleClick();
        }
      }}
    >
      {isLoading ? (
        <Spinner as='span' animation='border' size='sm' role='status' />
      ) : (
        text
      )}
    </Button>
  );
};

export default LoadingButton;
