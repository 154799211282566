import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import Heading from '../Heading';
import { Action } from '../utils/riskAssessmentReducer';
import ProductionAreaSelect from './ProductionAreaSelect';
import PropertySelect from './PropertySelect';
import SprayDateInput from './SprayDateInput';
import SprayTimeInput from './SprayTimeInput';

interface Props {
  property: UserProperty | null;
  sprayDate: string;
  sprayTime: string;
  productionArea: PropertyBlock | null;
  dispatch: React.Dispatch<Action>;
}

const RiskAssessmentInputs = ({
  property,
  sprayDate,
  sprayTime,
  productionArea,
  dispatch
}: Props): JSX.Element => {
  return (
    <>
      <Row className='mb-3'>
        <Col>
          <Heading className='mb-1' level='h6'>
            Select a Property
          </Heading>
          <PropertySelect property={property} dispatch={dispatch} />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Heading className='mb-1' level='h6'>
            Select a Production Area
          </Heading>
          <ProductionAreaSelect
            productionArea={productionArea}
            property={property}
            dispatch={dispatch}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Heading className='mb-1' level='h6'>
            Intended Spray Date
          </Heading>
          <SprayDateInput sprayDate={sprayDate} dispatch={dispatch} />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Heading className='mb-1' level='h6'>
            Intended Spray Time
          </Heading>
          <SprayTimeInput sprayTime={sprayTime} dispatch={dispatch} />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(observer(RiskAssessmentInputs));
