import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { count } from '../../../../utils/utils';
import FormField from '../../../helpers/forms/FormField';
import { GeneralNotesBody } from '../GeneralNotesCard';

const GeneralNotesForm = (
  props: FormikProps<GeneralNotesBody>
): JSX.Element => {
  return (
    <>
      <FormField
        fieldName={'general_notes'}
        fieldAs='textarea'
        rows={count(props.values.general_notes || '', '\n') + 2}
        props={props}
      />
    </>
  );
};

export default observer(GeneralNotesForm);
