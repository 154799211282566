import { Button, Card } from 'react-bootstrap';
import './sprayplanguidecard.scss';

const SprayPlanGuideCard = (): JSX.Element => {
  return (
    <>
      <Card className='spm-guide-card bg-light my-3 justify-content-center d-flex'>
        <Card.Body className='m-md-5'>
          <h2 className='text-primary d-inline-block pb-1'>
            What is a Spray Plan for?
          </h2>

          <div className='pb-3 w-50'>
            <p>
              Property Spray Plans are about{' '}
              <b>identifying the risk of spraying</b> and outlining how
              agrichemicals can be responsibly managed and used.
            </p>
            <p>
              Spray Plans should be practical, clear, and updated regularly. All
              employees involved in spraying operations as well as neighbours
              should receive a copy of your property Spray Plan and be briefed
              on its content.
            </p>
          </div>
          <Button
            variant='primary'
            className='d-block d-md-inline-block mb-3 mb-md-0'
            onClick={() =>
              window.open('https://wiki.hortplus.com/en/spray-plan-guide')
            }
          >
            See Spray Plan Guide
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default SprayPlanGuideCard;
