import { useContext, useMemo } from 'react';
import {
  Feature,
  GeometryMultiPolygon,
  GeometryPolygon
} from '../models/types';
import { UserPropertyContext } from '../stores/StoreContexts';
import { randomString } from './utils';

export const useMultiBoundary = () => {
  const userPropertyStore = useContext(UserPropertyContext);

  const objectBoundaries = useMemo(() => {
    return (
      userPropertyStore.selectedProperty?.boundary
        ?.geometry as unknown as GeometryMultiPolygon
    )?.coordinates.map((value: number[][][], index: number) => {
      // Set ids if none set, or if there are more polygons than ids.
      const property = userPropertyStore.selectedProperty;
      if (!property?.boundary_ids) {
        const newBoundaryIds = [randomString(32)];
        userPropertyStore.setBoundaryIds(newBoundaryIds);
      } else if (property.boundary_ids.length === index) {
        userPropertyStore.setBoundaryIds([
          ...property?.boundary_ids,
          randomString(32)
        ]);
      }
      // Create new 'single' Polygon (with it's id).
      const newPolygonFeature: Feature = {
        id: (property?.boundary_ids && property?.boundary_ids[index]) || '',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [value[0]],
          properties: {}
        } as GeometryPolygon
      };
      return newPolygonFeature;
    }) as unknown as Feature[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPropertyStore.selectedProperty?.boundary?.geometry]);

  return objectBoundaries;
};
