import { faArrowLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { errorToast } from '../helpers/toasts/ToastUtils';

export const AddNewImage = async (
  userPropertyStore: PropertyStore,
  data: FormData
) => {
  const imageResponse = await userPropertyStore.setMapImage(data);
  if (imageResponse.ok) {
    userPropertyStore.requestProperties();
  } else {
    errorToast('Something went wrong');
  }
};

const PropertyMapUpload = (): JSX.Element => {
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [imageUploaded, setUploaded] = useState(false);
  const userPropertyStore = useContext(UserPropertyContext);
  useData();

  const fileSelectedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(e.target.files![0]);
  };

  const fileUploadHandler = (): boolean => {
    if (selectedImage) {
      const fd = new FormData();
      fd.append('map', selectedImage);
      AddNewImage(userPropertyStore, fd);
      return true;
    } else {
      errorToast('No file selected');
      return false;
    }
  };

  return (
    <Container className='px-5 py-3'>
      <div>
        <Row>
          <Col>
            <Button variant='link' onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>
          </Col>
          <Col className='text-center'>
            <h3 className='text-center'>Property Map</h3>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <b>{userPropertyStore.selectedProperty?.property_name}</b>
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <p>{userPropertyStore.selectedProperty?.address}</p>
          </Col>
        </Row>
      </div>
      {!imageUploaded ? (
        <div className='d-flex justify-content-center my-3'>
          <input
            name='map'
            type='file'
            accept='image/*'
            onChange={(e) => {
              if (e.target.files !== null) {
                fileSelectedHandler(e);
              }
            }}
          />
          <Button
            variant='outline-success'
            onClick={() => {
              const selected = fileUploadHandler();
              if (selected) {
                history.push(
                  `/property-details/${userPropertyStore.selectedPropertyId}`
                );
              }
            }}
          >
            Upload
          </Button>
        </div>
      ) : (
        <>
          <b>Image has been uploaded</b>
          <Button
            variant='link'
            className='text-danger'
            type='button'
            onClick={() => setUploaded(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </>
      )}
    </Container>
  );
};

export default PropertyMapUpload;
