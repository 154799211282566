import React from 'react';
import { Action, ActionKind } from '../utils/riskAssessmentReducer';

interface Props {
  sprayDate: string;
  dispatch: React.Dispatch<Action>;
}

const SprayDateInput = ({ sprayDate, dispatch }: Props): JSX.Element => {
  return (
    <input
      id='sprayDate'
      className='form-control'
      name='sprayDate'
      type='date'
      value={sprayDate}
      onChange={(e) =>
        dispatch({
          type: ActionKind.SET_SPRAY_DATE,
          payload: e.target.value
        })
      }
    />
  );
};

export default React.memo(SprayDateInput);
