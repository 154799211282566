import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import PropertyStore from '../../../stores/PropertyStore';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import Table from './Table';
import './tables.scss';
import { makeData } from './utils';

const HTTable = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const productHSNOs = userPropertyStore.productHSNOs;

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'blank-0',
        columns: [
          {
            Header: '',
            id: 'blank-0-0',
            columns: [
              {
                Header: '',
                id: 'blank-0-0-0',
                columns: [
                  {
                    Header: 'Product',
                    id: 'product',
                    accessor: 'product'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '',
        id: 'blank-1',
        columns: [
          {
            Header: '',
            id: 'blank-1-1',
            columns: [
              {
                Header: 'Tracking controls apply',
                columns: [
                  {
                    Header: '',
                    id: 'blank-1-1-1',
                    accessor: 'tracking'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '',
        id: 'blank-2',
        columns: [
          {
            Header: '',
            id: 'blank-2-2',
            columns: [
              {
                Header: 'Approved handler controls apply',
                columns: [
                  {
                    Header: '',
                    id: 'blank-2-2-2',
                    accessor: 'approval'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.1 Acute Toxicant',
        columns: [
          {
            Header: 'Dermal',
            columns: [
              {
                Header: 'May be harmful in contact with skin',
                columns: [
                  {
                    Header: 'E',
                    id: 'E-0',
                    accessor: '61xdermalxE'
                  }
                ]
              },
              {
                Header: 'Harmful in contact with skin',
                columns: [
                  {
                    Header: 'D',
                    id: 'D-0',
                    accessor: '61xdermalxD'
                  }
                ]
              },
              {
                Header: 'Toxic in contact with skin',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-0',
                    accessor: '61xdermalxC'
                  }
                ]
              },
              {
                Header: 'Fatal in contact with skin',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-0',
                    accessor: '61xdermalxB'
                  }
                ]
              },
              {
                Header: 'Fatal in contact with skin-',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-0',
                    accessor: '61xdermalxA'
                  }
                ]
              }
            ]
          },
          {
            Header: 'Inhalation',
            columns: [
              {
                Header: 'May be harmful if inhaled',
                columns: [
                  {
                    Header: 'E',
                    id: 'E-1',
                    accessor: '61xinhalationxE'
                  }
                ]
              },
              {
                Header: 'Harmful if inhaled',
                columns: [
                  {
                    Header: 'D',
                    id: 'D-1',
                    accessor: '61xinhalationxD'
                  }
                ]
              },
              {
                Header: 'Toxic if inhaled',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-1',
                    accessor: '61xinhalationxC'
                  }
                ]
              },
              {
                Header: 'Fatal if inhaled',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-1',
                    accessor: '61xinhalationxB'
                  }
                ]
              },
              {
                Header: 'Fatal if inhaled-',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-1',
                    accessor: '61xinhalationxA'
                  }
                ]
              }
            ]
          },
          {
            Header: 'Oral',
            columns: [
              {
                Header: 'May be harmful if swallowed',
                columns: [
                  {
                    Header: 'E',
                    id: 'E-2',
                    accessor: '61xoralxE'
                  }
                ]
              },
              {
                Header: 'Harmful if swallowed',
                columns: [
                  {
                    Header: 'D',
                    id: 'D-2',
                    accessor: '61xoralxD'
                  }
                ]
              },
              {
                Header: 'Toxic if swallowed',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-2',
                    accessor: '61xoralxC'
                  }
                ]
              },
              {
                Header: 'Fatal if swallowed',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-2',
                    accessor: '61xoralxB'
                  }
                ]
              },
              {
                Header: 'Fatal if swallowed-',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-2',
                    accessor: '61xoralxA'
                  }
                ]
              }
            ]
          },
          {
            Header: 'Aspiration',
            columns: [
              {
                Header: 'May be fatal if swallowed and enters airways',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-3',
                    accessor: '61xaspirationxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.3',
        columns: [
          {
            Header: 'Skin Irritant',
            columns: [
              {
                Header: 'Causes mild skin irritation',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-4',
                    accessor: '63xSkin IrritantxB'
                  }
                ]
              },
              {
                Header: 'Causes skin irritation',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-4',
                    accessor: '63xSkin IrritantxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.4',
        columns: [
          {
            Header: 'Eye Irritant',
            columns: [
              {
                Header: 'Causes eye irritation',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-5',
                    accessor: '64xEye IrritantxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.5',
        columns: [
          {
            Header: 'Sensitisation',
            columns: [
              {
                Header: 'May cause an allergic skin reaction',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-6',
                    accessor: '65xSensitisationxB'
                  }
                ]
              },
              {
                Header:
                  'May cause allergy or asthma symptoms or breathing difficulties if inhaleds',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-6',
                    accessor: '65xSensitisationxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.6',
        columns: [
          {
            Header: 'Mutagen',
            columns: [
              {
                Header: 'Suspected of causing genetic defects',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-7',
                    accessor: '66xMutagenxB'
                  }
                ]
              },
              {
                Header: 'May cause genetic defects',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-7',
                    accessor: '66xMutagenxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.7',
        columns: [
          {
            Header: 'Carcinogen',
            columns: [
              {
                Header: 'Suspected of causing cancer',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-8',
                    accessor: '67xCarcinogenxB'
                  }
                ]
              },
              {
                Header: 'May cause cancer',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-8',
                    accessor: '67xCarcinogenxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.8',
        columns: [
          {
            Header: 'Reproductive Development',
            columns: [
              {
                Header: 'May cause harm to breast-fed children',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-9',
                    accessor: '68xReproductiveDevelopmentalxC'
                  }
                ]
              },
              {
                Header: 'Suspected of damaging fertility or the unborn child',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-9',
                    accessor: '68xReproductiveDevelopmentalxB'
                  }
                ]
              },
              {
                Header: 'May damage fertility or the unborn child',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-9',
                    accessor: '68xReproductiveDevelopmentalxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '6.9',
        columns: [
          {
            Header: 'Target Organ',
            columns: [
              {
                Header:
                  'May cause damage to organs through prolonged or repeated exposure',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-10',
                    accessor: '69xTarget OrganxB'
                  }
                ]
              },
              {
                Header:
                  'Causes damage to organs through prolonged or repeated exposure',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-10',
                    accessor: '69xTarget OrganxA'
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    []
  );

  const data = useMemo<readonly { [x: string]: {} }[]>(
    () => makeData(productHSNOs, 6),
    [productHSNOs]
  );

  return (
    <div className='httable'>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default observer(HTTable);
