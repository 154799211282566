import { BasicSprayProduct } from '../../../models/propertyTypes';
import { ProductChecked } from '../utils/riskAssessmentReducer';

export const updateProductsChecked = (
  current: ProductChecked[],
  product: BasicSprayProduct | undefined,
  checked: boolean
) => {
  const newProductChecked: ProductChecked[] = current.map((pc) => {
    if (
      pc.product?.id === ({ product, checked } as ProductChecked).product?.id
    ) {
      return {
        ...pc,
        checked: ({ product, checked } as ProductChecked).checked
      };
    }
    return {
      ...pc
    };
  });
  return newProductChecked;
};
