import { UserProperty } from '../../../models/userTypes';
import PropertyStore from '../../../stores/PropertyStore';
import { ApiDelete, ApiPost } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';

//POST and DELETE requests for adding new empty items
// Sensitives, Neighbours, SPRAY Personal Certifications

export function AddNewSensitive(userPropertyStore: PropertyStore) {
  ApiPost(
    'property/' + userPropertyStore.selectedPropertyId + '/sensitive-area',
    { description: '', management: '' }
  ).then((res) => {
    if (res.ok) userPropertyStore.getSensitives();
    else {
      errorToast('Something went wrong');
    }
  });
}

export function AddNewNeighbour(userPropertyStore: PropertyStore) {
  ApiPost('property/' + userPropertyStore.selectedPropertyId + '/neighbour', {
    name: '',
    address: '',
    phone: '',
    email: ''
  }).then((res) => {
    if (res.ok) userPropertyStore.getNeighbours();
    else {
      errorToast('Something went wrong');
    }
  });
}

export function AddNewPersonnel(userPropertyStore: PropertyStore) {
  ApiPost('property/' + userPropertyStore.selectedPropertyId + '/personnel', {
    name: '',
    phone: '',
    email: ''
  }).then((res) => {
    if (res.ok) userPropertyStore.getPersonnel();
    else {
      errorToast('Something went wrong');
    }
  });
}

export function DeleteSensitive(userPropertyStore: PropertyStore, id: number) {
  ApiDelete(
    'property/' + userPropertyStore.selectedPropertyId + '/sensitive-area/' + id
  ).then((res) => {
    if (res.ok) {
      userPropertyStore.getSensitives();
    } else {
      errorToast('Something went wrong');
    }
  });
}

export function DeleteNeighbour(userPropertyStore: PropertyStore, id: number) {
  ApiDelete(
    'property/' + userPropertyStore.selectedPropertyId + '/neighbour/' + id
  ).then((res) => {
    if (res.ok) {
      userPropertyStore.getNeighbours();
    } else {
      errorToast('Something went wrong');
    }
  });
}

export function DeletePersonnel(userPropertyStore: PropertyStore, id: number) {
  ApiDelete(
    'property/' + userPropertyStore.selectedPropertyId + '/personnel/' + id
  ).then((res) => {
    if (res.ok) {
      userPropertyStore.getPersonnel();
    } else {
      errorToast('Something went wrong');
    }
  });
}

export function DeleteCertification(
  userPropertyStore: PropertyStore,
  pid: number,
  id: number
) {
  ApiDelete(
    'property/' +
      userPropertyStore.selectedPropertyId +
      '/personnel/' +
      pid +
      '/certification/' +
      id
  ).then((res) => {
    if (res.ok) userPropertyStore.getPersonnel();
    else {
      errorToast('Something went wrong');
    }
  });
}

export function DeleteMapImage(
  userPropertyStore: PropertyStore,
  property: UserProperty | undefined
) {
  if (property) {
    ApiDelete(`property/${property.id}/map-image`).then((res) => {
      if (res.ok) {
        if (userPropertyStore.selectedProperty) {
          userPropertyStore.selectedProperty.property_image = [];
        }
      } else {
        userPropertyStore.getPropertyImage(property);
        errorToast('Something went wrong');
      }
    });
  }
}
