import { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext, UserPropertyContext } from '../../stores/StoreContexts';
import UserDetails from './UserDetails';

const Account = (): JSX.Element => {
  const userStore = useContext(UserContext);
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      <Container className='px-3 py-3'>
        <h2 className='text-center mb-3'>Account</h2>
        <UserDetails />
        <div className='my-2 d-flex justify-content-center mt-3'>
          <Button
            className='mx-4'
            variant='primary'
            as={Link}
            to='/change-password'
          >
            Change Password
          </Button>
          <Button
            className='mr-2'
            variant='secondary'
            onClick={() => {
              userStore.logout();
              userPropertyStore.clearProperties();
            }}
          >
            Logout
          </Button>
        </div>
      </Container>
    </>
  );
};

export default Account;
