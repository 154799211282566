import { useContext } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';

const Personnel = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const personnel = userPropertyStore.selectedProperty?.property_personnel
    ? userPropertyStore.selectedProperty?.property_personnel
    : [];

  return (
    <div className='spray-personnel'>
      <ul className='list-unstyled'>
        {personnel?.length === 0 || undefined || null ? (
          <p className='empty-card-text'>No personnel</p>
        ) : (
          <>
            {personnel?.map((person, index) => (
              <div key={person.id}>
                <Row className='pl-3'>
                  <Col className='col-5'>
                    <Row>
                      <b>Operator</b>
                    </Row>
                    <Row>
                      <span>
                        Name:{' '}
                        {personnel[index].name || (
                          <i style={{ color: 'gray' }}>(blank name)</i>
                        )}
                      </span>
                    </Row>
                    <Row>
                      <span>
                        Phone:{' '}
                        {personnel[index].phone || (
                          <i style={{ color: 'gray' }}>(blank phone)</i>
                        )}
                      </span>
                    </Row>
                    <Row>
                      <span>
                        Email:{' '}
                        {personnel[index].email || (
                          <i style={{ color: 'gray' }}>(blank email)</i>
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col xs={7}>
                    <Row>
                      <Container>
                        <Row>
                          <b>Certifications</b>
                        </Row>
                      </Container>
                      {/** Only display table if there are certifications. */}
                      {person.personnel_certifications &&
                      person.personnel_certifications.length > 0 ? (
                        <Table striped hover size='sm' responsive>
                          <thead>
                            <tr>
                              <th>Certificate</th>
                              <th>Number/Code</th>
                              <th>Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {person.personnel_certifications?.map(
                              (certificate, i) => (
                                <tr key={i}>
                                  <td>
                                    {certificate.name || (
                                      <i style={{ color: 'gray' }}>
                                        (blank certificate)
                                      </i>
                                    )}
                                  </td>
                                  <td>
                                    {certificate.number || (
                                      <i style={{ color: 'gray' }}>
                                        (blank number/code)
                                      </i>
                                    )}
                                  </td>
                                  <td>
                                    {certificate.expire || (
                                      <i style={{ color: 'gray' }}>
                                        (blank expiry date)
                                      </i>
                                    )}
                                  </td>
                                </tr>
                              )
                            ) || <tr></tr>}
                          </tbody>
                        </Table>
                      ) : (
                        <i className='mt-2'>No Certifications Added</i>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default Personnel;
