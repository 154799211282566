import { observer } from 'mobx-react-lite';
import { FormLabel, OverlayTrigger } from 'react-bootstrap';
import { Option } from '../../../../../models/types';
import { BlockSpray } from '../../../../../models/userTypes';
import ProductSelect from '../../../../helpers/forms/ProductSelect';
import {
  Action,
  putUpdateDispatch,
  State
} from '../utils/sprayPlanCardReducer';
import { renderTooltip } from './utils/renderTooltip';

interface Props {
  spray: BlockSpray;
  property: string;
  label: string;
  message: string;
  state: State;
  dispatch: React.Dispatch<Action>;
}

const SprayPlanCardProductSelect = ({
  spray,
  property,
  label,
  message,
  state,
  dispatch
}: Props): JSX.Element => {
  const selectChange = (v: Option) => {
    putUpdateDispatch(state, dispatch, spray, property, v.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <OverlayTrigger
        placement='right'
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, message)}
      >
        <FormLabel>
          <b>{label}</b>
        </FormLabel>
      </OverlayTrigger>
      <ProductSelect inputValue={spray.licence_no} onChange={selectChange} />
    </div>
  );
};

export default observer(SprayPlanCardProductSelect);
