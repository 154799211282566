import {
  faClipboardCheck,
  faCog,
  faHome,
  faInfo
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './navbar.scss';

const Navbar = (): JSX.Element => {
  return (
    <div className='tabs-container'>
      <ul className='nav-tabs'>
        <li className='tab-item'>
          <Link className='tab-link' to='/dashboard'>
            <span>
              <FontAwesomeIcon icon={faHome} />
            </span>
            &nbsp;Properties
          </Link>
        </li>
        <li className='tab-item'>
          <Link className='tab-link' to='/risk-assessment'>
            <span>
              <FontAwesomeIcon icon={faClipboardCheck} />
            </span>
            &nbsp;Risk Assessment
          </Link>
        </li>
        <li
          className='tab-item'
          onClick={() =>
            window.open('https://wiki.hortplus.com/en/spray-plan-guide')
          }
        >
          <Link className='tab-link px-3' to={window.location.pathname}>
            <span>
              <FontAwesomeIcon icon={faInfo} />
            </span>
            &nbsp;Guide
          </Link>
        </li>
        <li className='tab-item'>
          <Link className='tab-link' to='/account'>
            <span>
              <FontAwesomeIcon icon={faCog} />
            </span>
            &nbsp;Account
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
