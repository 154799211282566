import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { UserContext } from '../../stores/StoreContexts';
import UserStore, { loginDetails } from '../../stores/UserStore';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import './login.scss';

const Login = (): JSX.Element => {
  const userStore = useContext<UserStore>(UserContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  async function login() {
    // Currently not using any input validation.
    const details: loginDetails = {
      email: email,
      password: password
    };
    userStore.login(details);
  }

  return userStore.loggedIn ? (
    <Redirect to='/dashboard' />
  ) : (
    <Container className='mt-5'>
      <Row>
        <Col className='text-center'>
          <h1>Login</h1>
        </Col>
      </Row>
      <Row className='mt-3' style={{ justifyContent: 'center' }}>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Form
                onSubmit={async (event) => {
                  event.preventDefault();
                  await login();
                }}
              >
                <Form.Group className='form-group' controlId='emailInput'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-3' controlId='passwordInput'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className='text-center'>
                  <Link to='/forgot-password'>
                    <Button variant='outline-secondary' style={{ margin: 5 }}>
                      Forgot password
                    </Button>
                  </Link>
                  <Button variant='primary' type='submit' style={{ margin: 5 }}>
                    Login
                  </Button>{' '}
                </Form.Group>
              </Form>
            </Card.Body>
            {userStore.loading ? <LoadingOverlay /> : <></>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(Login);
