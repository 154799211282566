//
// Get a cookie by cookie name
//

import { countBy } from 'lodash';
import PropertyStore from '../stores/PropertyStore';

export const environment = process.env.NODE_ENV;

export function getCookie(name: string) {
  name += '=';
  let cookiesString: string = decodeURIComponent(document.cookie);
  let cookiesArr: string[] = cookiesString.split(';');
  // Search for cookie in cookies array
  for (let i = 0; i < cookiesArr.length; i++) {
    let c: string = cookiesArr[i];
    // Remove whitespace before cookie name
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    // Check if cookie name matches
    if (c.indexOf(name) === 0) {
      // Return cookie value
      return c.substring(name.length, c.length);
    }
  }
  // No cookie found
  return '';
}

//
// Get XSRF Token from backend API (Required before user login/register)
//
export function InitXSRFToken() {
  //Make a request to the sanctum endpoint for the xsrf-token
  fetch(process.env.REACT_APP_SPRAYPLAN_API_URL + 'sanctum/csrf-cookie', {
    method: 'GET',
    credentials: 'include'
  })
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        return {
          error: 'There was an error!'
        };
      }
    })
    .catch((error) => {
      if (environment === 'development') {
        console.log(
          `An error occured with getting Csrf-cookie (Is the backend running?)`
        );
      }
    });
}

// Description: Make a GET request and get back the response.
// Usage: provide path from 'api/' onwards, and body as object.
// Make sure to assert type for the promise you get back!
export async function ApiGet(path: string, bodyOnly?: boolean): Promise<any> {
  try {
    const res = await fetch(
      process.env.REACT_APP_SPRAYPLAN_API_URL + 'api/' + path,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: 'GET',
        credentials: 'include'
      }
    );
    return bodyOnly ? res.json() : res;
  } catch (error) {
    if (environment === 'development') {
      console.log(
        `An error has occured with API GET: \n Path: ${path} \n${error}`
      );
    }
  }
}

// Description: Make a POST request and get back the response.
// Usage: provide path from 'api/' onwards, and body as object.
// Make sure to assert type for the promise you get back!
export async function ApiPost(
  path: string,
  body: Object,
  bodyOnly?: boolean
): Promise<any> {
  try {
    const res = await fetch(
      process.env.REACT_APP_SPRAYPLAN_API_URL + 'api/' + path,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include'
      }
    );
    return bodyOnly ? res.json() : res;
  } catch (error) {
    if (environment === 'development') {
      console.log(
        `An error has occured with API POST: \nPath: ${path} \n${error}`
      );
    }
  }
}

export async function ApiPut(
  path: string,
  body: Object,
  bodyOnly?: boolean
): Promise<any> {
  try {
    const res = await fetch(
      process.env.REACT_APP_SPRAYPLAN_API_URL + 'api/' + path,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: 'PUT',
        body: JSON.stringify(body),
        credentials: 'include'
      }
    );
    return bodyOnly ? res.json() : res;
  } catch (error) {
    if (environment === 'development') {
      console.log(
        `An error has occured with API PUT: \nPath: ${path} \n${error}`
      );
    }
  }
}

export async function ApiDelete(
  path: string,
  bodyOnly?: boolean
): Promise<any> {
  try {
    const res = await fetch(
      process.env.REACT_APP_SPRAYPLAN_API_URL + 'api/' + path,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: 'DELETE',
        credentials: 'include'
      }
    );
    return bodyOnly ? res.json() : res;
  } catch (error) {
    if (environment === 'development') {
      console.log(
        `An error has occured with API DELETE: \nPath: ${path} \n${error}`
      );
    }
  }
}

export async function ApiPutImage(
  path: string,
  body: FormData,
  bodyOnly?: boolean
): Promise<any> {
  try {
    const res = await fetch(
      process.env.REACT_APP_SPRAYPLAN_API_URL + 'api/' + path,
      {
        headers: {
          Accept: 'application/json',
          'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        },
        method: 'POST',
        body: body,
        credentials: 'include'
      }
    );
    return bodyOnly ? res.json() : res;
  } catch (error) {
    if (environment === 'development') {
      console.log(
        `An error has occured with API PUT: \nPath: ${path} \n${error}`
      );
    }
  }
}

/**
 * Clears user specific local storage items. This ensures that
 * when another user logs in to the same browser, they will not
 * use a previous user's local storage items.
 * Add any user specific local storage items to the
 * "itemsToClear" array.
 */
export const clearUsersLocalStorage = () => {
  const itemsToClear = ['risk-assessment'];
  itemsToClear.forEach((item) => localStorage.removeItem(item));
};

export const getPAName = (
  userPropertyStore: PropertyStore,
  propertyId: number,
  productionId: any
) => {
  const productionAreaName = userPropertyStore.properties
    ?.filter((property) => {
      return property.id === Number(propertyId);
    })[0]
    ?.property_blocks!.filter((area) => {
      return area.id === Number(productionId);
    })[0].name;

  return productionAreaName;
};

/**
 * Returns the number of occurences of string 'char' in string 'str';
 */
export const count = (str: string, char: string) => countBy(str)[char] || 0;

export const randomString = (len: number): string =>
  Array.from(Array(len), () =>
    Math.floor(Math.random() * 36).toString(36)
  ).join('');
