import { Slide, toast, ToastContainer as Toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const successToast = (text: string, id?: string) => {
  toast(text, {
    type: toast.TYPE.SUCCESS,
    theme: 'light',
    toastId: id
  });
};

export const errorToast = (text: string, id?: string) => {
  toast(text, {
    type: toast.TYPE.ERROR,
    theme: 'light',
    toastId: id
  });
};

/**
 * Sets out the functionality, styling and limitations of the toasts.
 * By implementing this component in App.tsx it allows for a global toast system.
 *
 */
export const ToastContainer = () => (
  <Toastify
    position='top-right'
    limit={3}
    autoClose={5000}
    hideProgressBar={true}
    closeOnClick
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
    transition={Slide}
    closeButton={false}
    toastClassName={() => 'text-center bg-white text-dark p-3 m-2 shadow-sm'}
  />
);

// This could be used in another way to have a 'loading' toast,
// once loaded the toast can get updated using the Ref so that it turns green
// eg: loading -> Success!
// const toastId = useRef<ReactText>('updatePageSettings');

// const notify = () =>
//   (toastId.current = toast('Updating...', {
//     type: toast.TYPE.INFO,
//     autoClose: false
//   }));
