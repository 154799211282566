import { useCallback, useContext } from 'react';
import { UserPropertyContext } from '../../stores/StoreContexts';

export const useHSNOsData = () => {
  const userPropertyStore = useContext(UserPropertyContext);
  const productHSNOs = userPropertyStore.productHSNOs;

  const getDangerHSNOs = useCallback(() => {
    return productHSNOs
      ?.map((list) => {
        const product = list.product;
        let hsnos = list.hsnos;
        hsnos = hsnos.filter((hsno) => {
          return hsno.signal_word === 'danger';
        });
        return { product, hsnos };
      })
      .filter((list) => list.hsnos.length > 0);
  }, [productHSNOs]);

  const dangerHSNOs = getDangerHSNOs();

  return [dangerHSNOs, productHSNOs];
};
