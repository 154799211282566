import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useRef, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost, getPAName } from '../../utils/utils';
import ProductSelect from '../helpers/forms/ProductSelect';
import { errorToast } from '../helpers/toasts/ToastUtils';
import { addSprayPlanSchema } from '../validation_schemas/FormSchemas';
import ModalFormField from './ModalFormField';
import ModalSelectFormField from './ModalSelectFormField';

interface NewSprayPlan {
  licence_no?: string;
  target?: string;
  applications?: string;
  timing?: string;
}

type Props = {
  propertyId: number;
  productionId: number;
};

const AddSprayPlan = ({ propertyId, productionId }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const addButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showModal, setShowModal] = useState(false);

  const initialValues: NewSprayPlan = {
    applications: '',
    target: '',
    timing: ''
  };
  const productionAreaName = getPAName(
    userPropertyStore,
    propertyId,
    productionId
  );

  return (
    <div>
      <Button
        ref={addButtonRef}
        variant='outline-success'
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        Add Spray Product
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add a spray product to <b>{productionAreaName}</b>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={addSprayPlanSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const response = await ApiPost(
              `property/${propertyId}/production-area/${productionId}/block-spray`,
              values
            );
            if (response?.ok) {
              await userPropertyStore.fetchBlockSprays(
                Number(propertyId),
                Number(productionId)
              );
            } else {
              errorToast('Something went wrong');
            }
            // Ensure 'add' button is visible before form submitted.
            addButtonRef.current?.scrollIntoView({ block: 'end' });
            setSubmitting(false);
            setShowModal(false);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  Add the spray product, the purpose, the number of applications
                  and aproximate timing.
                </p>
                <p>
                  Use the Product Lookup Tool for detailed product information
                  including recommended personal protective equipment (PPE).
                </p>
                <ModalSelectFormField
                  label='Product Type'
                  subLabel='The spray product'
                  fieldName='product_id'
                  errorName='product_id'
                  required
                >
                  <ProductSelect
                    onChange={(v) => {
                      props.setFieldValue('licence_no', v.value);
                    }}
                  />
                </ModalSelectFormField>
                <ModalFormField
                  label='Applications'
                  subLabel='The amount per season'
                  fieldName='applications'
                  fieldAs='input'
                  placeholder='Applications, the amount per season.'
                  errorName='applications'
                />
                <ModalFormField
                  label='Target'
                  subLabel='The spray target'
                  fieldName='target'
                  fieldAs='input'
                  placeholder='Target'
                  errorName='target'
                />
                <ModalFormField
                  label='Timing'
                  subLabel='The approximate timing'
                  fieldName='timing'
                  fieldAs='input'
                  placeholder='Timing'
                  errorName='timing'
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setShowModal(false)}
                  variant='outline-secondary'
                  disabled={props.isSubmitting}
                >
                  Cancel
                </Button>
                <Button variant='success' type='submit' onClick={() => {}}>
                  {!props.isSubmitting ? (
                    <>Create New Spray Product</>
                  ) : (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                    />
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddSprayPlan);
