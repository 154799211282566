import { findIndex } from 'lodash';
import mapboxgl from 'mapbox-gl';
import { useContext } from 'react';
import {
  Feature,
  FeatureCollection,
  GeometryPolygon
} from '../../../models/types';
import { Boundary } from '../../../models/userTypes';
import PropertyStore from '../../../stores/PropertyStore';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiDelete, ApiPut, randomString } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';

export const monoStyle = 'cklubq40e06ej17ojtb4pg2o1';
export const satelliteStyle = 'cknpikbkn0jut17nwxfi4mbh8';

type RequestFunctions = [
  (data: FeatureCollection) => void,
  (data: FeatureCollection, features: Feature[]) => void,
  (data: FeatureCollection, features: Feature[]) => void
];

export const useMapRequests = (): RequestFunctions => {
  const userPropertyStore = useContext(UserPropertyContext);

  const create = async (data: FeatureCollection) => {
    // The newest created feature is the last feature in 'data.features'.
    const feature = data.features[data.features.length - 1];
    if (feature.geometry.type === 'Polygon') {
      // Convert Polygon features to one MultiPolygon feature.
      const polygons: Feature[] = data.features.filter(
        (feature) => feature.geometry.type === 'Polygon'
      );
      const multiPolygon = polygonsToMultiPolygon(
        polygons,
        userPropertyStore.selectedProperty?.boundary_id || feature.id
      );
      const res = await ApiPut(
        `property/${userPropertyStore.selectedPropertyId}/property-boundary`,
        multiPolygon as any
      );
      if (res && res.ok) {
        userPropertyStore.setBoundaryIds([
          ...(userPropertyStore.selectedProperty?.boundary_ids || []),
          randomString(32)
        ]);
        userPropertyStore.setBoundary(multiPolygon as unknown as Boundary);
      } else {
        errorToast('Something went wrong');
      }
    }
    if (userPropertyStore.selectedProperty) {
      userPropertyStore.selectedProperty.map_type = 'GIS';
    }
  };

  const update = async (data: FeatureCollection, features: Feature[]) => {
    const feature = features[0];
    const propertyPlaces = userPropertyStore.selectedProperty?.propertyPlaces;
    if (feature.geometry.type === 'Polygon') {
      // Convert Polygon features to one MultiPolygon feature.
      const polygons: Feature[] = data.features.filter(
        (feature) => feature.geometry.type === 'Polygon'
      );
      const toUpdateIndex = polygons.findIndex(
        (_feature) => _feature.id === feature.id
      );
      polygons[toUpdateIndex] = feature;
      const multiPolygon = polygonsToMultiPolygon(
        polygons,
        userPropertyStore.selectedProperty?.boundary_id || feature.id
      );
      userPropertyStore.setBoundary(multiPolygon as unknown as Boundary);
      const res = await ApiPut(
        `property/${userPropertyStore.selectedPropertyId}/property-boundary`,
        multiPolygon as any
      );
      if (!res.ok) {
        userPropertyStore.getBoundary();
        errorToast('Something went wrong');
      }
    } else {
      const index = findIndex(
        propertyPlaces,
        (place) => place.id === feature.id
      );
      if (propertyPlaces) {
        propertyPlaces[index] = feature as any;
        userPropertyStore.setPropertyPlaces(propertyPlaces);
      }
      const res = await ApiPut(
        `property/${userPropertyStore.selectedPropertyId}/property-place/${feature.properties.id}`,
        feature as any
      );
      if (!res.ok) {
        userPropertyStore.getPlaces();
        errorToast('Something went wrong');
      }
    }
  };

  const onDelete = async (data: FeatureCollection, features: Feature[]) => {
    const feature = features[0];
    if (feature.geometry.type === 'Polygon') {
      const polygons: Feature[] = data.features.filter(
        (feature) => feature.geometry.type === 'Polygon'
      );
      const toDeleteIndex = polygons.findIndex(
        (_feature) => _feature.id === feature.id
      );
      polygons.splice(toDeleteIndex, 1);
      const multiPolygon = polygonsToMultiPolygon(
        polygons,
        userPropertyStore.selectedProperty?.boundary_id || feature.id
      );
      const newBoundaryIds =
        userPropertyStore.selectedProperty?.boundary_ids || [];
      newBoundaryIds?.splice(toDeleteIndex, 1);
      // If no bonudaries then delete boundary, otherwise update boundary.
      if (multiPolygon.geometry.coordinates.length === 0) {
        userPropertyStore.setBoundaryIds([]);
        userPropertyStore.setBoundary(undefined);
        const res = await ApiDelete(
          `property/${userPropertyStore.selectedPropertyId}/property-boundary`
        );
        if (!res.ok) {
          userPropertyStore.getBoundary();
          errorToast('Something went wrong');
        }
      } else {
        userPropertyStore.setBoundaryIds(newBoundaryIds);
        userPropertyStore.setBoundary(multiPolygon as unknown as Boundary);
        const res = await ApiPut(
          `property/${userPropertyStore.selectedPropertyId}/property-boundary`,
          multiPolygon as any
        );
        if (!res.ok) {
          userPropertyStore.getBoundary();
          errorToast('Something went wrong');
        }
      }
    } else {
      const res = await ApiDelete(
        `property/${userPropertyStore.selectedPropertyId}/property-place/${feature.properties.id}`
      );
      if (res && res.ok) {
        userPropertyStore.getPlaces();
      } else {
        errorToast('Something went wrong');
      }
    }
  };
  return [create, update, onDelete];
};

export const deleteProperty = async (userPropertyStore: PropertyStore) => {
  const propertyId = userPropertyStore.selectedPropertyId;
  const propertyPlaces = userPropertyStore.selectedProperty?.propertyPlaces;

  userPropertyStore.setBoundary({} as Boundary);
  userPropertyStore.setPropertyPlaces([]);

  // Delete boundary
  const res = await ApiDelete(`property/${propertyId}/property-boundary`);
  if (!res.ok) {
    userPropertyStore.getBoundary();
    errorToast('Something went wrong');
  }

  // Delete all property places.
  propertyPlaces?.forEach(async (place) => {
    const res = await ApiDelete(
      `property/${propertyId}/property-place/${place.properties.id}`
    );
    if (!res.ok) {
      userPropertyStore.getPlaces();
      errorToast('Something went wrong');
    }
  });
};

export const hasProperty = (userPropertyStore: PropertyStore) => {
  const boundary = userPropertyStore.selectedProperty?.boundary;
  const propertyPlaces = userPropertyStore.selectedProperty?.propertyPlaces;
  return boundary && propertyPlaces && propertyPlaces.length > 0;
};

export const fitToBounds = (
  map: any,
  animate: boolean,
  padding: number,
  features: Feature[]
) => {
  // Create array of all coordinates from boundary and
  // property places.
  const coordinates: [number, number][] = [];
  features.forEach((feature) => {
    if (!feature.geometry) return;
    if (feature.geometry.type === 'Point') {
      const coord = feature.geometry.coordinates as [number, number];
      coordinates.push(coord);
    } else if (feature.geometry.type === 'Polygon') {
      const coords = feature.geometry.coordinates as number[][][];
      coords[0].forEach((coord) => coordinates.push(coord as [number, number]));
    }
  });
  const bounds = new mapboxgl.LngLatBounds();
  for (const coord of coordinates) {
    bounds.extend(coord as [number, number]);
  }
  map.fitBounds(bounds, {
    animate,
    padding
  });
  return;
};

const polygonsToMultiPolygon = (
  polygons: Feature[],
  boundary_id: string | undefined
): Feature => {
  // Convert Polygon features to one MultiPolygon feature.
  const multiPolygon: Feature = {
    id: boundary_id || '',
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'MultiPolygon',
      coordinates: polygons.map(
        (polygon) => (polygon.geometry as GeometryPolygon).coordinates
      ),
      properties: {}
    }
  };
  return multiPolygon;
};
