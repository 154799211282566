import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PersonnelCertifications } from '../../models/userTypes';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from './ModalFormField';

interface NewPersonnel {
  name: string;
  phone: number;
  email: string;
  personnel_certifications?: PersonnelCertifications[];
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddPersonnel = ({ showModal, setShowModal }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialValues: NewPersonnel = {
    name: '',
    // This cast ensures the initial value of 0 is not placed in the form
    phone: '' as unknown as number,
    email: '',
    personnel_certifications: []
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add a person
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            actions.resetForm();
            ApiPost(
              'property/' + userPropertyStore.selectedPropertyId + '/personnel',
              values
            )
              .then((res) => {
                if (res.ok) userPropertyStore.getPersonnel();
                else {
                  errorToast('Something went wrong');
                }
              })
              .finally(() => {
                setShowModal(false);
                actions.setSubmitting(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  Add the the spray personnel details below. Fill in the details
                  you have. The record can be updated at any time.
                </p>
                <ModalFormField
                  label='Name'
                  subLabel='Their full name'
                  fieldName='name'
                  fieldAs='input'
                  placeholder='Name'
                  errorName='name'
                />
                <ModalFormField
                  label='Phone'
                  subLabel='Their contact number'
                  fieldName='phone'
                  fieldAs='input'
                  placeholder='Phone'
                  errorName='phone'
                />
                <ModalFormField
                  label='Email'
                  subLabel='Their email address'
                  fieldName='email'
                  fieldAs='input'
                  fieldType='email'
                  placeholder='Email'
                  errorName='email'
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create New Personnel
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddPersonnel);
