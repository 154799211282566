import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Select } from 'react-select-virtualized';
import { UserProperty } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import {
  Action,
  ActionKind
} from '../../production_areas/production_cards/utils/copySpraysReducer';

interface Option {
  label: string;
  value: UserProperty;
}

interface Props {
  property: UserProperty | null;
  dispatch: React.Dispatch<Action>;
}

const PropertySelect = ({ property, dispatch }: Props): JSX.Element => {
  const initialValue = property
    ? ({
        label: property?.property_name,
        value: property
      } as Option)
    : null;

  const userPropertyStore = useContext(UserPropertyContext);
  const [value, setValue] = useState<Option | null>(initialValue);

  // Set options to properties that are 'complete'.
  const options = userPropertyStore.properties
    ?.map((property: UserProperty) => {
      if (!userPropertyStore.isSprayPlanReady(property.id)) return null;
      return {
        label: property.property_name || '',
        value: property
      };
    })
    .filter((option) => option !== null) as Option[] | undefined;

  useEffect(() => {
    setValue(() =>
      property
        ? ({
            label: property?.property_name,
            value: property
          } as Option)
        : null
    );
  }, [property]);

  return (
    <Select
      options={options ? options : []}
      placeholder='Select a Property'
      value={value}
      onChange={async (v: Option) => {
        setValue(v);
        if (!v) userPropertyStore.setSelectedProperty(-1);
        else if (v.value.id) {
          userPropertyStore.setSelectedProperty(v.value.id);
          await userPropertyStore.getProductionAreaDetails(v.value.id);
        }
        dispatch({
          type: ActionKind.SET_PROPERTY,
          payload: v?.value || null
        });
      }}
    />
  );
};

export default observer(PropertySelect);
