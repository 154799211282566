import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from './ModalFormField';

interface NewNeighbours {
  name: string;
  address: string;
  phone: string;
  email: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddNeighbours = ({ showModal, setShowModal }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialValues: NewNeighbours = {
    name: '',
    address: '',
    phone: '',
    email: ''
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add neighbour to&nbsp;
            <b>{userPropertyStore.selectedProperty?.property_name}</b>
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            actions.resetForm();
            ApiPost(
              'property/' + userPropertyStore.selectedPropertyId + '/neighbour',
              values
            )
              .then((res) => {
                if (res.ok) userPropertyStore.getNeighbours();
                else {
                  errorToast('Something went wrong');
                }
              })
              .finally(() => {
                setShowModal(false);
                actions.setSubmitting(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  Add the the neighbours details below. Fill in the details you
                  have. The record can be updated at any time.
                </p>
                <ModalFormField
                  label='Name'
                  subLabel='Their full name'
                  fieldName='name'
                  fieldAs='input'
                  placeholder='Name'
                  errorName='name'
                />
                <ModalFormField
                  label='Address'
                  subLabel='Their physical address'
                  fieldName='address'
                  fieldAs='input'
                  placeholder='Address'
                  errorName='address'
                />
                <ModalFormField
                  label='Phone'
                  subLabel='Their contact number'
                  fieldName='phone'
                  fieldAs='input'
                  placeholder='Phone'
                  errorName='phone'
                />
                <ModalFormField
                  label='Email'
                  subLabel='Their email address'
                  fieldName='email'
                  fieldAs='input'
                  fieldType='email'
                  placeholder='Email'
                  errorName='email'
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create New Neighbour
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddNeighbours);
