import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import ConfirmationModal from '../../helpers/modals/ConfirmationModal';
import { DeleteMapImage } from '../property_cards/PropertyCardUtils';

interface Props {
  setHasMap?: React.Dispatch<React.SetStateAction<boolean>>;
  deletable?: boolean;
}

const PropertyImage = ({
  setHasMap,
  deletable = false
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const property = userPropertyStore.selectedProperty;
  const imageObject = property?.property_image;
  const image =
    imageObject && imageObject.length > 0 ? imageObject[0].body : null;

  return (
    <>
      {image && (
        <div>
          <img
            style={{ maxWidth: '100%' }}
            src={`data:image/png;base64,${btoa(image)}`}
            alt='Property map'
          />
          {deletable && (
            <div className='d-flex justify-content-center my-3'>
              <div className='mr-3'>
                <Button
                  variant='success'
                  as={Link}
                  to={`/property-details/${property?.id}/image`}
                >
                  New Image
                </Button>
              </div>
              <div>
                <Button
                  variant='outline-danger'
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        title='Confirm delete?'
        body={
          <p>
            Do you wish to delete the property map image from the
            <b> {userPropertyStore.selectedProperty?.property_name}.</b>
          </p>
        }
        confirmBtnText='Delete'
        confirmBtnVariant='danger'
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        confirmAction={() => {
          DeleteMapImage(userPropertyStore, userPropertyStore.selectedProperty);
          deletable && setHasMap && setHasMap(false);
        }}
      />
    </>
  );
};

export default PropertyImage;
