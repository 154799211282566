import { BasicSprayProduct } from '../../../models/propertyTypes';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';

export interface ProductChecked {
  product: BasicSprayProduct | undefined;
  checked: boolean;
}

export interface Delcaration {
  name: string;
  date: string;
}

export interface State {
  property: UserProperty | null;
  productionArea: PropertyBlock | null;
  sprayDate: string;
  sprayTime: string;
  forecastImpact: string;
  products: ProductChecked[];
  declaration: Delcaration | null;
}

export enum ActionKind {
  SET_PROPERTY = 'SET_PROPERTY',
  SET_PRODUCTION_AREA = 'SET_PRODUCTION_AREA',
  SET_SPRAY_DATE = 'SET_SPRAY_DATE',
  SET_SPRAY_TIME = 'SET_SPRAY_TIME',
  SET_FORECAST_IMPACT = 'SET_FORECAST_IMPACT',
  SET_PRODUCT = 'SET_PRODUCT',
  ADD_PRODUCT = 'ADD_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  CHECK_PRODUCT = 'CHECK_PRODUCT',
  SET_DECLARATION = 'SET_DECLARATION',
  RESET = 'RESET'
}

export type Action =
  | { type: ActionKind; payload: UserProperty }
  | { type: ActionKind; payload: PropertyBlock }
  | { type: ActionKind; payload: string }
  | { type: ActionKind; payload: ProductChecked }
  | { type: ActionKind; payload: ProductChecked[] }
  | { type: ActionKind; payload: Delcaration }
  | { type: ActionKind; payload: State };

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionKind.SET_PROPERTY:
      if (!payload) {
        return {
          ...state,
          property: payload as UserProperty,
          productionArea: payload as unknown as PropertyBlock
        };
      }
      return {
        ...state,
        property: payload as UserProperty
      };
    case ActionKind.SET_PRODUCTION_AREA:
      return {
        ...state,
        productionArea: payload as PropertyBlock
      };
    case ActionKind.SET_SPRAY_DATE:
      return {
        ...state,
        sprayDate: payload as string
      };
    case ActionKind.SET_SPRAY_TIME:
      return {
        ...state,
        sprayTime: payload as string
      };
    case ActionKind.SET_FORECAST_IMPACT:
      return {
        ...state,
        forecastImpact: payload as string
      };
    case ActionKind.SET_PRODUCT:
      return {
        ...state,
        products: payload as ProductChecked[]
      };
    case ActionKind.ADD_PRODUCT:
      if (!payload) return state;
      return {
        ...state,
        products: [...state.products, payload as ProductChecked]
      };
    case ActionKind.CHECK_PRODUCT:
      if (!payload) return state;
      const newProducts: ProductChecked[] = state.products.map(
        (productChecked) => {
          if (
            productChecked.product?.id ===
            (payload as ProductChecked).product?.id
          ) {
            return {
              ...productChecked,
              checked: (payload as ProductChecked).checked
            };
          }
          return {
            ...productChecked
          };
        }
      );
      return {
        ...state,
        products: newProducts
      };
    case ActionKind.SET_DECLARATION:
      return {
        ...state,
        declaration: payload as Delcaration
      };
    case ActionKind.RESET:
      return {
        ...(payload as State),
        sprayDate: '',
        sprayTime: ''
      };
    default:
      return state;
  }
};

export const reducerInitialState = (): State => {
  const now = new Date();
  return {
    property: null,
    productionArea: null,
    sprayDate: now.toISOString().split('T')[0],
    sprayTime: `${
      now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
    }:${now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()}`,
    forecastImpact: '',
    products: [],
    declaration: null
  };
};
