import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PropertySensitives } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import LoadingButton from '../../../helpers/buttons/LoadingButton';
import FormField from '../../../helpers/forms/FormField';
import { DeleteSensitive } from '../PropertyCardUtils';

function SensitiveForm(
  props: FormikProps<{
    property_sensitives: PropertySensitives[];
  }>
) {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <b>Description</b>
        </Col>
        <Col>
          <b>Management</b>
        </Col>
        <Col md={2}></Col>
      </Row>
      {props.values.property_sensitives?.map((area, index) => (
        <Row key={area.id}>
          <FormField
            fieldName={`property_sensitives[${index}].description`}
            fieldAs='input'
            message='A description of the sensitive area'
            props={props}
          />
          <FormField
            fieldName={`property_sensitives[${index}].management`}
            fieldAs='input'
            message='Actions you will take to manage the sensitive area'
            props={props}
          />
          <Col xs={12} md={2}>
            <LoadingButton
              variant='outline-danger link'
              onClick={() => DeleteSensitive(userPropertyStore, area.id)}
              text='Delete'
            />
          </Col>
        </Row>
      ))}
    </>
  );
}

export default observer(SensitiveForm);
