import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import AddProductionArea from '../../modals/AddProductionArea';
import IncompleteAlert from '../../properties/IncompleteAlert';
import './dashboardCard.scss';
import SprayPlanReportButton from './SprayPlanReportButton';

type Props = {
  details: UserProperty;
};

const DashboardCard = ({ details }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  //const [user_property, set_user_property] = useState<UserProperty>();
  const [addProductionArea, setAddProductionArea] = useState(false);

  const history = useHistory();

  const user_property = details;

  const timeDiff = (area: PropertyBlock): number => {
    const now = new Date(Date.now());
    const areaDate = new Date(area.updated_at);
    const diff = Math.abs(now.getTime() - areaDate.getTime());
    return diff / (1000 * 3600 * 24);
  };

  const daysAgo = (value: number): string => {
    if (value <= 1) {
      return ' Updated less than 1 day ago';
    } else if (value > 365) {
      return ' Updated more than 1 year ago';
    } else {
      return ` Updated ${Math.ceil(value)} days ago`;
    }
  };

  return (
    <>
      <Container>
        <Row className='align-items-center my-3'>
          {/* Property List */}
          <Card className='property-item mb-3 w-100'>
            <Container
              className='p-3'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                userPropertyStore.setSelectedProperty(user_property?.id || 0);
                if (history.location.pathname === '/dashboard') {
                  history.push(`property-details/${user_property?.id || 0}`);
                }
              }}
            >
              {!userPropertyStore.isSprayPlanReady(details.id) ? (
                <IncompleteAlert property={details} />
              ) : null}
              <Row className='p-3'>
                {/** Propery name and address */}
                <Col md={4}>
                  <h3 className='property-name'>
                    {user_property?.property_name || 'Undefined Property Name'}
                  </h3>
                  <span className='property-address'>
                    {user_property?.address || 'Undefined Property Address'}
                  </span>
                </Col>
                {/** Production area */}
                <Col>
                  <h6>
                    <FontAwesomeIcon icon={faMap} />
                    &nbsp; Production Areas
                  </h6>
                  {user_property?.property_blocks?.length === 0 ? (
                    <div>
                      <em className='text-secondary'>
                        No production areas added
                      </em>
                    </div>
                  ) : (
                    <ul className='list-unstyled'>
                      {user_property?.property_blocks?.map((area) => (
                        <li key={area.id}>
                          <Link
                            to={`/production-area/${details.id}/${area.id}`}
                          >
                            {area.name ? (
                              <b>{area.name}</b>
                            ) : (
                              <i>(Blank name)</i>
                            )}
                          </Link>
                          <i>{daysAgo(timeDiff(area))}</i>
                        </li>
                      ))}
                    </ul>
                  )}
                </Col>
                {/** SP report and view property */}
                <Col md={3} className='dashboard-card-right text-right pr-0'>
                  <Container className='dashboard-card-right'>
                    <SprayPlanReportButton
                      userPropertyStore={userPropertyStore}
                      details={details}
                    />
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        </Row>
      </Container>

      <AddProductionArea
        showModal={addProductionArea}
        setShowModal={setAddProductionArea}
        selectedPropertyID={user_property?.id || 0}
      />
    </>
  );
};

export default observer(DashboardCard);
