import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Production } from '../../models/propertyTypes';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import { productionAreaSchema } from '../validation_schemas/FormSchemas';
import ModalFormField from './ModalFormField';
import ModalSelectFormField from './ModalSelectFormField';

export interface NewProductionArea {
  production_id?: string;
  name?: string;
  area?: number;
  review_date?: string;
  general_notes?: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPropertyID: number;
};

const AddProductionArea = ({
  showModal,
  setShowModal,
  selectedPropertyID
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const history = useHistory();

  let today = new Date();
  const offset = today.getTimezoneOffset();
  today = new Date(today.getTime() - offset * 60 * 1000);
  const dateString = today.toISOString().split('T')[0];

  const initialValues: NewProductionArea = {
    review_date: dateString,
    area: '' as any,
    general_notes: ''
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add production area to&nbsp;
            <b>{userPropertyStore.selectedProperty?.property_name}</b>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={productionAreaSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            const res = await ApiPost(
              `property/${selectedPropertyID}/production-area`,
              data
            );
            if (res.ok) {
              setSubmitting(false);
              setShowModal(false);
              const production = await res.json();
              await userPropertyStore.getProductionAreaDetails(
                selectedPropertyID
              );
              history.push(
                `/production-area/${userPropertyStore.selectedPropertyId}/${production.id}`
              );
            } else {
              errorToast('Something went wrong');
            }
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  Enter your production area details. This information can be
                  updated and changed at anytime.
                </p>
                <ModalSelectFormField
                  label='Product Type'
                  subLabel='What is planted or how the land is used'
                  fieldName='production_id'
                  fieldAs='select'
                  errorName='production_id'
                  required
                >
                  <>
                    <option value=''>-- Select a type --</option>
                    {userPropertyStore.productions?.map((type: Production) => (
                      <option key={`type_${type.type}`} value={type.id}>
                        {type.type}
                      </option>
                    ))}
                  </>
                </ModalSelectFormField>
                <ModalFormField
                  label='Name'
                  subLabel='The name for the area'
                  fieldName='name'
                  fieldAs='input'
                  placeholder='My production area'
                  errorName='name'
                  required
                />
                <ModalFormField
                  label='Area'
                  subLabel='The hectares of the production area'
                  fieldName='area'
                  fieldAs='input'
                  placeholder='Area in Hectares'
                  errorName='area'
                />
                <ModalFormField
                  label='Review Date'
                  subLabel='When this plan was last reviewed (dd/mm/yyyy)'
                  fieldName='review_date'
                  fieldType='date'
                  placeholder='Area in Hectares'
                  errorName='review_date'
                  required
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create Production Area
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddProductionArea);
