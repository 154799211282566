import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import BTable from 'react-bootstrap/Table';
import { Column, HeaderGroup, Row, useTable } from 'react-table';

interface Props {
  columns: readonly Column<{
    [x: string]: {};
  }>[];
  data: readonly {
    [x: string]: {};
  }[];
}

const Table = ({ columns, data }: Props): JSX.Element => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <BTable striped bordered hover size='sm' {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: HeaderGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              // Vertical text columns.
              if (column.depth === 2) {
                return (
                  <th
                    className='verticalTableHeader'
                    {...column.getHeaderProps()}
                  >
                    <div>{column.render('Header')}</div>
                  </th>
                );
                // Product column.
              } else if (column.id === 'product') {
                return (
                  <th
                    className='text-center'
                    style={{ minWidth: '175px' }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                );
                // Remaining columns.
              } else {
                return (
                  <th
                    className='text-center table-top-columns'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                );
              }
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row: Row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.value === 'X') {
                  return (
                    <td
                      style={{
                        padding: 0
                      }}
                      {...cell.getCellProps()}
                    >
                      <div
                        style={{
                          backgroundColor: '#ff0000',
                          textAlign: 'center',
                          display: 'block',
                          height: '100%',
                          fontWeight: 'bold',
                          paddingTop: '6px',
                          paddingBottom: '6px',
                          minHeight: '16px'
                        }}
                      >
                        <FontAwesomeIcon icon={faExclamation} />
                      </div>
                    </td>
                  );
                } else {
                  return (
                    <td className='products' {...cell.getCellProps()}>
                      <b>{cell.render('Cell')}</b>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export default observer(Table);
