import React from 'react';
import { Button, Modal } from 'react-bootstrap';

//Usage:
// Required props:
// Title - text to be shown on header of modal
// Body - text to be shown in body of modal
// ConfirmAction - provide a callback function.
// showModal - boolean state of modal display
// setShowModal - callback function to manipulate modal display state

// Optional props:
// confirmBtnText - text to show in confirmation button. If not provided, default is 'Confirm'
// cancelBtnText - text to show in cancel button. Default value is 'Cancel'
// confirmBtnVariant - bootstrap class to apply to button for changing colour. Default is 'secondary'

type Props = {
  title: string;
  body: JSX.Element;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmBtnVariant?: string;
  confirmAction: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmationModal = ({
  title,
  body,
  confirmBtnText,
  cancelBtnText,
  confirmBtnVariant,
  confirmAction,
  showModal,
  setShowModal
}: Props): JSX.Element => {
  return (
    <Modal
      show={showModal}
      backdrop='static'
      onHide={() => {
        setShowModal(false);
      }}
      centered
      style={{ border: 'none' }}
    >
      <Modal.Header style={{ backgroundColor: '#363743' }}>
        <Modal.Title style={{ color: 'white', fontSize: 20 }}>
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowModal(false)} variant='outline-secondary'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            confirmAction();
            setShowModal(false);
          }}
          variant={confirmBtnVariant || 'primary'}
        >
          <b>{confirmBtnText || 'Confirm'}</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
