import { PropertyBlock, UserProperty } from '../../../../models/userTypes';

export interface State {
  property: UserProperty | null;
  productionArea: PropertyBlock | null;
}

export enum ActionKind {
  SET_PROPERTY = 'SET_PROPERTY',
  SET_PRODUCTION_AREA = 'SET_PRODUCTION_AREA'
}

export type Action =
  | { type: ActionKind; payload: UserProperty }
  | { type: ActionKind; payload: PropertyBlock };

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionKind.SET_PROPERTY:
      if (!payload) {
        return {
          ...state,
          property: payload as UserProperty,
          productionArea: payload as unknown as PropertyBlock
        };
      }
      return {
        ...state,
        property: payload as UserProperty,
        productionArea: null
      };
    case ActionKind.SET_PRODUCTION_AREA:
      return {
        ...state,
        productionArea: payload as PropertyBlock
      };
    default:
      return state;
  }
};

export const reducerInitialState = (): State => {
  return {
    property: null,
    productionArea: null
  };
};
