import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import SprayPlanCardEdit from './SprayPlanCardEdit/SprayPlanCardEdit';
import SprayPlanCardView from './SprayPlanCardView';

const SprayPlanCard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [editing, setEditing] = useState(false);
  let { propertyId, productionId }: any = useParams();

  const productionArea = userPropertyStore.properties
    ?.filter((property) => property.id === Number(propertyId))[0]
    ?.property_blocks!.filter((area) => area.id === Number(productionId))[0];

  if (editing) {
    return (
      <SprayPlanCardEdit
        productionArea={productionArea}
        setEditing={setEditing}
      />
    );
  } else {
    return (
      <SprayPlanCardView
        productionArea={productionArea}
        editing={editing}
        setEditing={setEditing}
      />
    );
  }
};

export default observer(SprayPlanCard);
