import { ButtonGroup, Button } from 'react-bootstrap';
import { ViewPort } from '../../../models/types';
import { monoStyle, satelliteStyle } from './PropertyMapUtils';

interface PreviewButtonProps {
  setMapStyle: React.Dispatch<React.SetStateAction<string>>;
  mapStyle: string;
  viewport: ViewPort;
}

const MapStyleButton = (props: PreviewButtonProps) => (
  <div
    className='m-2'
    style={{
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: `translate(-50%, 0)`
    }}
  >
    <ButtonGroup>
      <Button
        onClick={() => props.setMapStyle(monoStyle)}
        variant={props.mapStyle === monoStyle ? 'secondary' : 'light'}
        size='sm'
      >
        Map View
      </Button>
      <Button
        onClick={() => props.setMapStyle(satelliteStyle)}
        variant={props.mapStyle === satelliteStyle ? 'secondary' : 'light'}
        size='sm'
      >
        Satellite View
      </Button>
    </ButtonGroup>
  </div>
);

export default MapStyleButton;
