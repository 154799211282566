interface Props {
  forecastImpact: string | undefined;
}

const ReportForecastImpact = ({ forecastImpact }: Props): JSX.Element => {
  return (
    <div>
      <h5 className='sub-heading mb-3 font-weight-bold'>
        Expected Weather Forecast &amp; Impact
      </h5>
      <textarea
        className='form-control'
        rows={8}
        value={forecastImpact}
        disabled
      />
    </div>
  );
};

export default ReportForecastImpact;
