import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';

interface Props {
  productionArea: PropertyBlock;
}

const Sprays = ({ productionArea }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      {productionArea.block_sprays &&
      productionArea.block_sprays.length === 0 ? (
        <b className='empty-section'>No spray products listed</b>
      ) : (
        <Table striped hover size='sm' responsive>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Number of Applications</th>
              <th>Target</th>
              <th>Timing</th>
            </tr>
          </thead>
          <tbody>
            {(productionArea?.block_sprays &&
              productionArea?.block_sprays.map((areas, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {userPropertyStore.products?.filter(
                        (product) => product.licence_no === areas.licence_no
                      )[0]?.name || (
                          <i style={{ color: 'gray' }}>({areas.licence_no})</i>
                        ) || (
                          <i style={{ color: 'gray' }}>(blank prod. name)</i>
                        )}
                    </td>
                    <td>
                      {areas.applications || (
                        <i style={{ color: 'gray' }}>(blank applications)</i>
                      )}
                    </td>
                    <td>
                      {areas.target || (
                        <i style={{ color: 'gray' }}>(blank target)</i>
                      )}
                    </td>
                    <td>
                      {areas.timing || (
                        <i style={{ color: 'gray' }}>(blank timing)</i>
                      )}
                    </td>
                  </tr>
                );
              })) || <tr></tr>}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default observer(Sprays);
