import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PropertyNeighbours } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import LoadingButton from '../../../helpers/buttons/LoadingButton';
import FormField from '../../../helpers/forms/FormField';
import { DeleteNeighbour } from '../PropertyCardUtils';

function NeighboursForm(
  props: FormikProps<{
    property_neighbours: PropertyNeighbours[];
  }>
) {
  const userPropertyStore = useContext(UserPropertyContext);
  return (
    <>
      {props.values.property_neighbours?.map((neighbour, index) => (
        <>
          <Row key={neighbour.id}>
            <FormField
              label='Name'
              fieldName={`property_neighbours[${index}].name`}
              fieldAs='input'
              message='Their full name'
              props={props}
            />
            <FormField
              label='Address'
              fieldName={`property_neighbours[${index}].address`}
              fieldAs='input'
              message='Their physical address'
              props={props}
            />
          </Row>
          <Row>
            <FormField
              label='Phone'
              fieldName={`property_neighbours[${index}].phone`}
              fieldAs='input'
              message='Their contact number'
              props={props}
            />
            <FormField
              label='Email'
              fieldName={`property_neighbours[${index}].email`}
              fieldAs='input'
              message='Their email address'
              type='email'
              props={props}
            />
          </Row>
          <LoadingButton
            variant='outline-danger link'
            onClick={() => DeleteNeighbour(userPropertyStore, neighbour.id)}
            text='Delete'
          />
          <hr></hr>
        </>
      ))}
    </>
  );
}

export default observer(NeighboursForm);
