import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { UserProperty } from '../../models/userTypes';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';

interface Props {
  property: UserProperty | undefined;
}

const IncompleteAlert = ({ property }: Props): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const propertyId = property?.id;

  return (
    <Alert className='rounded-0 text-dark' variant='warning'>
      Property incomplete: Add&nbsp;
      {userPropertyStore
        .missingSprayPlanComponents(property)
        .map((section, i, array) => (
          <b key={i}>
            <a
              className='text-dark'
              href={`/property-details/${propertyId}#${section
                .replace(/\s/g, '')
                .toLocaleLowerCase()}`}
            >
              {section}
            </a>
            {i < array.length - 1 ? ', ' : ''}
          </b>
        ))}
      &nbsp;to create a Spray Plan.
    </Alert>
  );
};

export default observer(IncompleteAlert);
