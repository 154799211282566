import { ErrorMessage, Field, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ApiPut } from '../../utils/utils';
import { errorToast, successToast } from '../helpers/toasts/ToastUtils';
import { changePasswordSchema } from '../validation_schemas/FormSchemas';

interface Values {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

const ChangePassword = (): JSX.Element => {
  let history = useHistory();

  const initialValues: Values = {
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  };

  return (
    <Container className='px-5 py-3'>
      <Row>
        <Col className='text-center'>
          <h2 className='text-center'>Change Password</h2>
        </Col>
      </Row>
      <Row className='mt-3' style={{ justifyContent: 'center' }}>
        <Col className='mb-5' md={8}>
          <Card>
            <Card.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={changePasswordSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(true);
                  ApiPut('user/change-password', values)
                    .then((res: Response) => {
                      if (res.ok) {
                        successToast('Password successfully changed');
                        history.push('/account');
                      } else {
                        errorToast('Something went wrong');
                      }
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Form.Group controlId='current_password'>
                      <Form.Label>
                        <b>Current Password</b>
                      </Form.Label>
                      <Field
                        name='current_password'
                        type='password'
                        placeholder='Enter your current password'
                        className='form-control'
                        required={true}
                      />
                      <ErrorMessage name='current_password' />
                    </Form.Group>
                    <Form.Group controlId='new_password'>
                      <Form.Label>
                        <b>New Password</b>
                      </Form.Label>
                      <Field
                        name='new_password'
                        type='password'
                        placeholder='Enter your current password'
                        className='form-control'
                        required={true}
                      />
                      <ErrorMessage name='new_password' />
                    </Form.Group>
                    <Form.Group controlId='new_password_confirmation'>
                      <Form.Label>
                        <b>Confirm Password</b>
                      </Form.Label>
                      <Field
                        name='new_password_confirmation'
                        type='password'
                        placeholder='Enter your current password'
                        className='form-control'
                        required={true}
                      />
                      <ErrorMessage name='new_password_confirmation' />
                    </Form.Group>

                    <div className='d-flex justify-content-center'>
                      <Button
                        className='mr-2'
                        variant='outline-secondary'
                        as={Link}
                        to='/account'
                      >
                        Cancel
                      </Button>
                      <Button variant='primary' type='submit'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(ChangePassword);
