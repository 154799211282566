import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import DangerProductsTable from '../../hazard_report/DangerProductsTable';
import EnvironmentToxicity from '../../hazard_report/EnvironmentToxicity';
import HumanToxicity from '../../hazard_report/HumanToxicity';
import { useHSNOsData } from '../../hazard_report/utils';

const ReportProductRisk = (): JSX.Element => {
  const [dangerHSNOs] = useHSNOsData();

  return (
    <div>
      <h5 className='sub-heading mb-3 font-weight-bold'>Products Risk</h5>
      <Row>
        <Col>
          <DangerProductsTable dangerHSNOs={dangerHSNOs} />
        </Col>
      </Row>
      <Row>
        <Col>
          <HumanToxicity />
        </Col>
      </Row>
      <Row>
        <Col>
          <EnvironmentToxicity />
        </Col>
      </Row>
    </div>
  );
};

export default observer(ReportProductRisk);
