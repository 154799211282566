import { Button } from 'react-bootstrap';
import { Feature } from '../../../models/types';
import { fitToBounds } from './PropertyMapUtils';

interface Props {
  mapRef: React.MutableRefObject<any>;
  objectBoundaries: Feature[];
  padding: number;
}

const MapFitBounds = ({
  mapRef,
  objectBoundaries,
  padding
}: Props): JSX.Element => {
  return (
    <div
      style={{
        position: 'absolute',
        marginRight: '10px',
        bottom: 40,
        right: 0
      }}
    >
      <Button
        size='sm'
        disabled={objectBoundaries ? objectBoundaries.length === 0 : true}
        onClick={() => {
          fitToBounds(
            mapRef.current.getMap(),
            true,
            padding,
            [...objectBoundaries] || []
          );
        }}
      >
        Fit Map
      </Button>
    </div>
  );
};

export default MapFitBounds;
