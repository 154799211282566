import { ErrorMessage, Field } from 'formik';
import { Form as rbForm, FormLabel } from 'react-bootstrap';

interface Props {
  label: string;
  subLabel: string;
  fieldName: string;
  fieldAs?: string;
  fieldType?: string;
  placeholder: string;
  errorName: string;
  required?: boolean;
}

const ModalFormField = ({
  label,
  subLabel,
  fieldName,
  fieldAs,
  fieldType,
  placeholder,
  errorName,
  required = false
}: Props): JSX.Element => {
  return (
    <rbForm.Group>
      <FormLabel>
        <b>{label}</b>
        {required ? <i className='ml-3'>(required)</i> : null}
        <rbForm.Text style={{ fontSize: '14px' }}>{subLabel}</rbForm.Text>
      </FormLabel>
      <Field
        name={fieldName}
        as={fieldAs}
        type={fieldType}
        className='form-control'
        placeholder={placeholder}
        required={required}
      />
      <ErrorMessage
        name={errorName}
        render={(msg) => (
          <div style={{ fontSize: '16px', color: 'red' }}>{msg}</div>
        )}
      />
    </rbForm.Group>
  );
};

export default ModalFormField;
