import ReactGA from 'react-ga4';
import { useLocation, withRouter } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();
  const path = location.pathname + location.search;

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'production'
  ) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }

  return <></>;
};

export default withRouter(GoogleAnalytics);
