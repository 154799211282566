import { Field, FormikProps } from 'formik';
import { Col, Form, FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

interface Props {
  label?: string;
  fieldName: string;
  placeholder?: string;
  fieldAs?: string;
  type?: string;
  message?: string;
  rows?: number;
  props?: FormikProps<any>;
}

const renderTooltip = (
  props: OverlayInjectedProps,
  message: string | undefined
) => <>{message && <Tooltip id='button-tooltip'>{message}</Tooltip>}</>;

const FormField = ({
  label,
  fieldName,
  placeholder,
  fieldAs,
  type,
  message,
  rows,
  props
}: Props): JSX.Element => {
  return (
    <Form.Group as={Col}>
      {label && (
        <OverlayTrigger
          placement='right'
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => renderTooltip(props, message)}
        >
          <FormLabel>
            <b>{label}</b>
          </FormLabel>
        </OverlayTrigger>
      )}
      <Field
        type={type}
        rows={rows}
        name={fieldName}
        placeholder={placeholder}
        as={fieldAs}
        className='form-control'
        disabled={props?.isSubmitting}
      />
    </Form.Group>
  );
};

export default FormField;
