import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import Applications from './Applications';
import Hazards from './Hazards';
import Sprays from './Sprays';

interface Props {
  productionArea: PropertyBlock;
}

const Heading = ({ children }: { children: string }) => {
  return (
    <h6 style={{ color: 'gray' }}>
      <b>{children}</b>
    </h6>
  );
};

const ProductionArea = ({ productionArea }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      <Row className='productionarea pb-5'>
        <Col>
          <h5>
            <b>{productionArea.name}</b>
          </h5>
          <hr></hr>
          <Col>
            <Row>
              <span>
                <b>Production Type / Land Use: </b>
                {userPropertyStore.productions?.filter(
                  (production) =>
                    production.id === productionArea?.production_id
                )[0].type || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Production Area Name: </b>
                {productionArea?.name || (
                  <i style={{ color: 'gray' }}>(blank)</i>
                )}
              </span>
            </Row>
            <Row>
              <span>
                <b>Land Area (Hectares): </b>
                {productionArea?.area ? (
                  `${productionArea.area} (ha)`
                ) : (
                  <i style={{ color: 'gray' }}>(blank)</i>
                )}
              </span>
            </Row>
            <Row>
              <span>
                <b>Review Date : </b>
                {(productionArea?.review_date &&
                  new Date(productionArea.review_date).toLocaleDateString(
                    'en-NZ',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    }
                  )) || <i style={{ color: 'gray' }}>(blank)</i>}
              </span>
            </Row>
          </Col>
        </Col>
      </Row>
      <Row className='pb-5'>
        <Col>
          <Heading>Spray Plan</Heading>
          <hr></hr>
          <Sprays productionArea={productionArea} />
        </Col>
      </Row>
      <Row className='pb-5'>
        <Col>
          <Heading>Hazards</Heading>
          <hr></hr>
          <Hazards productionArea={productionArea} />
        </Col>
      </Row>
      <Row className='pb-5'>
        <Col>
          <Heading>Application Methods</Heading>
          <hr></hr>
          <Applications productionArea={productionArea} />
        </Col>
      </Row>
    </>
  );
};

export default observer(ProductionArea);
