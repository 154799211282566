import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { ApiDelete, getPAName } from '../../utils/utils';
import HazardReportButton from '../dashboard/dashboard_card/HazardReportButton';
import ConfirmationModal from '../helpers/modals/ConfirmationModal';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ApplicationCard from './production_cards/ApplicationCard';
import GeneralNotesCard from './production_cards/GeneralNotesCard';
import HazardsCard from './production_cards/HazardsCard';
import ProductionInfoCard from './production_cards/ProductionInfoCard';
import SprayPlanCard from './production_cards/SprayPlanCard/SprayPlanCard';

const ProductionAreas = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const history = useHistory();
  let { propertyId, productionId } = useParams<any>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useData(Number(propertyId));

  useEffect(() => {
    if (!userPropertyStore.products) userPropertyStore.getProducts();
  }, [propertyId, userPropertyStore]);

  const name = userPropertyStore.properties?.filter(
    (property) => property.id === Number(propertyId)
  )[0]?.property_name;
  const propertyName = name;
  const productionAreaName =
    getPAName(userPropertyStore, propertyId, productionId) || '';

  return (
    <>
      {/* {userPropertyStore.loading ? (
        <LoadingOverlay />
      ) : ( */}
      <Container className='px-3 py-3'>
        <Row>
          <Col className='text-center pt-3'>
            <div style={{ float: 'left' }}>
              <Button
                className='pt-3'
                variant='link'
                onClick={() => history.goBack()}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Property
              </Button>
            </div>
          </Col>
          <Col className='text-center'>
            <h3>{productionAreaName}</h3>
            <h5>{propertyName}</h5>
          </Col>
          <Col className='text-center pt-3'>
            <div style={{ float: 'right' }}>
              <HazardReportButton />
            </div>
          </Col>{' '}
        </Row>
        <Row className='align-items-center my-3'>
          <Col>
            <ProductionInfoCard />
          </Col>
        </Row>
        <Row className='align-items-center my-3'>
          <Col>
            <SprayPlanCard />
          </Col>
        </Row>
        <Row className='align-items-center my-3'>
          <Col>
            <ApplicationCard />
          </Col>
        </Row>
        <Row className='align-items-center my-3'>
          <Col>
            <HazardsCard />
          </Col>
        </Row>
        <Row className='align-items-center my-3'>
          <Col>
            <GeneralNotesCard />
          </Col>
        </Row>
        <Row>
          <Col className='text-center pt-3 mb-3'>
            <Button
              variant='outline-danger link'
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Production Area
            </Button>
            <ConfirmationModal
              title='Confirm delete?'
              body={
                <p>
                  Are you sure you want to delete the production area
                  <b> {productionAreaName}</b>? This cannot be undone.
                </p>
              }
              confirmBtnText='Delete'
              confirmBtnVariant='danger'
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              confirmAction={() => {
                ApiDelete(
                  'property/' + propertyId + '/production-area/' + productionId
                ).then((res) => {
                  if (res.ok) {
                    userPropertyStore.getProductionAreaDetails(propertyId);
                    history.goBack();
                    if (userPropertyStore.selectedPropertyId) {
                      userPropertyStore.setSelectedProperty(
                        userPropertyStore.selectedPropertyId,
                        true
                      );
                    }
                  } else {
                    errorToast('Something went wrong');
                  }
                });
              }}
            ></ConfirmationModal>
          </Col>
        </Row>
      </Container>
      {/* )} */}
    </>
  );
};

export default observer(ProductionAreas);
