import { Feature } from '../../models/types';
import { PropertyImage as PImage } from '../../models/userTypes';
import PropertyImage from '../properties/map/PropertyImage';
import PropertyMap from '../properties/map/PropertyMap';
import {
  fitToBounds,
  satelliteStyle
} from '../properties/map/PropertyMapUtils';

interface Props {
  objectBoundaries: Feature[];
  objectPropertyPlaces: Feature[];
  imageObject: PImage[] | undefined;
  mapType: string | undefined;
}

const PMap = ({
  objectBoundaries,
  imageObject,
  mapType,
  objectPropertyPlaces
}: Props): JSX.Element => {
  return (
    <>
      {objectBoundaries?.length > 0 && mapType === 'GIS' ? (
        <PropertyMap
          initialStyle={satelliteStyle}
          consumerFunction={(map) => {
            fitToBounds(map, false, 20, [
              ...objectBoundaries,
              ...objectPropertyPlaces
            ]);
          }}
        />
      ) : imageObject && imageObject.length > 0 && mapType === 'OWN' ? (
        <PropertyImage />
      ) : (
        <p>No map created</p>
      )}
    </>
  );
};

export default PMap;
