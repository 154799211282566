import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PropertySensitives } from '../../models/userTypes';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from './ModalFormField';

interface NewSensitiveArea {
  description: string;
  management: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  sensitives: PropertySensitives[];
};

const AddSensitiveArea = ({
  showModal,
  setShowModal,
  sensitives
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialValues: NewSensitiveArea = { description: '', management: '' };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add sensitive area to&nbsp;
            <b>{userPropertyStore.selectedProperty?.property_name}</b>
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            actions.resetForm();
            ApiPost(
              'property/' +
                userPropertyStore.selectedPropertyId +
                '/sensitive-area',
              values
            )
              .then((res) => {
                if (res.ok) userPropertyStore.getSensitives();
                else {
                  errorToast('Something went wrong');
                }
              })
              .finally(() => {
                setShowModal(false);
                actions.setSubmitting(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  Add the description of the sensitive area and how you plan to
                  manage it.
                </p>
                <ModalFormField
                  label='Description'
                  subLabel='A description of the sensitive area'
                  fieldName='description'
                  fieldAs='input'
                  placeholder='Description'
                  errorName='description'
                />
                <ModalFormField
                  label='Management'
                  subLabel='Actions you will take to manage the sensitive area'
                  fieldName='management'
                  fieldAs='input'
                  placeholder='Management'
                  errorName='management'
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create Sensitive Area
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddSensitiveArea);
