import { PropertyBlock, UserProperty } from '../../models/userTypes';
import PropertyStore from '../../stores/PropertyStore';

export const getProductionAreas = (
  userPropertyStore: PropertyStore,
  property: UserProperty | undefined,
  blocks: PropertyBlock[] | undefined
) => {
  const productionAreas: PropertyBlock[] = [];
  if (property && blocks) {
    blocks.forEach((block) => {
      let productionArea = userPropertyStore.properties
        ?.filter((prop) => prop.id === property.id)[0]
        ?.property_blocks!.filter((area) => area.id === block.id)[0];
      if (productionArea) productionAreas.push(productionArea);
    });
  }
  return productionAreas;
};
