import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ApiPost } from '../../utils/utils';
import { errorToast, successToast } from '../helpers/toasts/ToastUtils';

interface Values {
  newpassword: string | undefined;
  newpassword_confirmation: string | undefined;
}

const ResetPassword = (): JSX.Element => {
  const history = useHistory();

  // Get id an token
  const { id, token } = useParams<{ id: string; token: string }>();

  const initialValues: Values = {
    newpassword: '',
    newpassword_confirmation: ''
  };

  return (
    <Container className='px-5 py-3'>
      <Row>
        <Col></Col>
        <Col className='text-center pb-2' md={8}>
          <h1 className='text-center'>Reset Password</h1>
        </Col>
        <Col></Col>
      </Row>
      <Row className='mt-3' style={{ justifyContent: 'center' }}>
        <Col className='mb-5' md={6}>
          <Card>
            <Card.Body>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                validate={(values) => {
                  const errors: any = {};
                  if (!values.newpassword) {
                    errors.newpassword = 'Required';
                  } else if (
                    values.newpassword !== values.newpassword_confirmation
                  ) {
                    errors.newpassword_confirmation =
                      'Please confirm your new password';
                  }

                  return errors;
                }}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(true);

                  // Construct request body
                  const body: Object = {
                    id: id,
                    token: token,
                    password: values.newpassword,
                    password_confirmation: values.newpassword_confirmation
                  };
                  ApiPost('user/reset-password', body)
                    .then(async (res) => {
                      if (res.ok) {
                        successToast('Password successfully reset');
                        history.push('/login');
                      } else {
                        const body = await res.json();
                        const message: string = body?.message;
                        if (!message) errorToast('Something went wrong');
                        else if (message.includes('expire')) {
                          errorToast(
                            'Password reset token expired. Please reset your password again.'
                          );
                          history.push('/forgot-password');
                        }
                      }
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }}
              >
                {(props) => (
                  <Form noValidate onSubmit={props.handleSubmit}>
                    <Form.Group controlId='newpassword'>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type='password'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.newpassword}
                        placeholder='Enter new password'
                        isValid={props.errors.newpassword ? true : false}
                      />
                      <div>
                        {props.touched.newpassword && props.errors.newpassword}
                      </div>
                    </Form.Group>

                    <Form.Group controlId='newpassword_confirmation'>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type='password'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.newpassword_confirmation}
                        placeholder='Confirm new password'
                        isValid={
                          props.errors.newpassword_confirmation ? true : false
                        }
                      />
                      <div>
                        {props.touched.newpassword_confirmation &&
                          props.errors.newpassword_confirmation}
                      </div>
                    </Form.Group>

                    <div className='d-flex justify-content-center'>
                      <Button
                        className='mr-2'
                        variant='outline-secondary'
                        as={Link}
                        to='/login'
                      >
                        Cancel
                      </Button>
                      <Button variant='primary' type='submit'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(ResetPassword);
