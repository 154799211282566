import { uniqBy } from 'lodash';
import { PropertyBlock, CheckedMethod } from '../../../models/userTypes';

export const getChecks = (
  headers: number[],
  productionArea: PropertyBlock | undefined
) => {
  // Keeping track of each unique spray method used by this property.
  let checks: CheckedMethod[] = [];

  // Set initial values for the form to display, so that selects and checkboxes are displaying correctly.
  for (const header of headers) {
    checks.push({ methodId: header, sprays: [] });
  }

  headers.forEach((header: number) => {
    checks.push({ methodId: header, sprays: [] });
  });

  // Filter out duplicate methods.
  checks = uniqBy(checks, (c) => c.methodId);

  // Associate the block sprays to each application method.
  for (const ch of checks) {
    for (const bs of productionArea?.block_sprays || []) {
      for (const sp of bs.spray_methods || []) {
        if (sp.method_id === ch.methodId) {
          ch.sprays?.push(`${bs.id}`);
        }
      }
    }
  }
  return checks;
};

/**
 * Creates the inital values for the Formik
 * form in ApplicationCard.
 *
 * @param headers
 * @param productionArea
 * @returns
 */
export const createInitialValues = (
  headers: number[],
  productionArea: PropertyBlock | undefined
) => {
  const checks = getChecks(headers, productionArea);
  const values = {
    sprays: checks,
    otherApplication: productionArea?.other_application || ''
  };
  return values;
};

export const createHeaders = (productionArea: PropertyBlock | undefined) => {
  const headers: number[] = [];

  for (const prod of productionArea?.block_sprays || []) {
    for (const sp of prod.spray_methods || []) {
      if (!headers.includes(sp.method_id)) headers.push(sp.method_id);
    }
  }
  return headers;
};
