import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost, getPAName } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from './ModalFormField';

interface NewHazard {
  description: string;
  management: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  propertyId: number;
  productionId: number;
};

const AddHazard = ({
  showModal,
  setShowModal,
  propertyId,
  productionId
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialValues: NewHazard = {
    description: '',
    management: ''
  };

  const productionAreaName = getPAName(
    userPropertyStore,
    propertyId,
    productionId
  );

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add a hazard to <b>{productionAreaName}</b>
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            actions.resetForm();
            ApiPost(
              `property/${propertyId}/production-area/${productionId}/block-hazard`,
              values
            )
              .then((res) => {
                if (res.ok)
                  userPropertyStore.getProductionAreaDetails(propertyId);
                else {
                  errorToast('Something went wrong');
                }
              })
              .finally(() => {
                setShowModal(false);
                actions.setSubmitting(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p>
                  <b>Hazards are about the products that are used.</b>
                </p>
                <p>
                  The Hazard Report creates a summary of the hazards associated
                  with this production area's Spray Plan, based on HSNO
                  classifications.
                </p>
                <p>
                  Open the Hazard Report and use it to help identify the
                  toxicity of the products and detail how you plan to manage
                  each of them.
                </p>
                <p>
                  The main hazards are <b>worker exposure</b> during mixing and
                  application, and <b>environmental toxicity</b> on water, soil,
                  bees, birds and animals.
                </p>

                <ModalFormField
                  label='Description'
                  subLabel='A description of the hazard'
                  fieldName='description'
                  fieldAs='input'
                  placeholder='Description'
                  errorName='description'
                />
                <ModalFormField
                  label='Management'
                  subLabel='Actions you will take to manage the hazard'
                  fieldName='management'
                  fieldAs='input'
                  placeholder='Management'
                  errorName='management'
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create New Hazard
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddHazard);
