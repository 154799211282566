import { Container } from 'react-bootstrap';
import './footer.scss';

const Footer = (): JSX.Element => {
  return (
    <div id='footer' className='w-100 bg-dark'>
      <Container>
        <div className='footer d-flex justify-content-center align-items-center'>
          <div className='text-center'>
            SprayPlan Manager made by{' '}
            <a href='https://www.hortplus.com' target='_blank' rel='noreferrer'>
              HortPlus
            </a>
            <br />
            <a
              href='https://www.hortplus.com/support'
              target='_blank'
              rel='noreferrer'
            >
              Technical Support
            </a>{' '}
            |{' '}
            <a
              href='https://www.hortplus.com/contact'
              target='_blank'
              rel='noreferrer'
            >
              Contact
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
