import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import Heading from './Heading';
import ETTable from './tables/ETTable';

const EnvironmentToxicity = (): JSX.Element => {
  return (
    <div>
      <Row>
        <Col>
          <Heading>HSNO Class 9 - Environment Toxicity</Heading>
          <ETTable />
        </Col>
      </Row>
    </div>
  );
};

export default observer(EnvironmentToxicity);
