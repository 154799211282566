import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const HazardReportButton = (): JSX.Element => {
  let { propertyId, productionId }: any = useParams();

  return (
    <Button
      variant='success'
      onClick={() => {
        window.open(`/hazard-report/${propertyId}/${productionId}`);
      }}
    >
      <FontAwesomeIcon icon={faClipboard} className='mr-1' /> View Hazard Report
    </Button>
  );
};

export default observer(HazardReportButton);
