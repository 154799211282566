import { useState } from 'react';
import { Button } from 'react-bootstrap';
import ConfirmationModal from '../helpers/modals/ConfirmationModal';

interface Props {
  onClear: () => void;
}

const ClearFormModal = ({ onClear }: Props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant='outline-danger'
        onClick={() => {
          setShow(true);
        }}
      >
        Clear Form
      </Button>
      <ConfirmationModal
        title={'Confirm Clear Form'}
        body={
          <p>
            Are you sure you want to clear the form? This action cannot be
            undone.
          </p>
        }
        confirmAction={function (): void {
          onClear();
        }}
        showModal={show}
        setShowModal={setShow}
      />
    </>
  );
};

export default ClearFormModal;
