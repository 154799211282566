import { Col, Container, Image, Row } from 'react-bootstrap';

const Credits = (): JSX.Element => {
  return (
    <>
      <Container fluid>
        <hr />
        <Container className='pt-3'>
          <Row className='justify-content-center align-items-center'>
            <Col md={{ span: 4 }}>
              <div className='mb-3'>
                <Image
                  src={
                    process.env.REACT_APP_S3_ASSETS + 'sprayplan-logo-dark.svg'
                  }
                  alt='SprayPlan Manager Logo'
                ></Image>
              </div>
              <p>
                Identify the risks and undertake responsible management and
                mitigation of adverse spray application effects on people and
                the environment.
              </p>
            </Col>
            <Col md={{ span: 7, offset: 1 }} className='text-center'>
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'nz-gap.png'}
                alt='NZ GAP'
                height='50'
                className='m-3'
              />
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'global-gap.png'}
                alt='Global GAP'
                height='50'
                className='m-3'
              />
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'growsafe-tick.jpg'}
                alt='Growsafe'
                height='50'
                className='m-3'
              />
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'hortplus.png'}
                alt='HortPlus'
                height='50'
                className='m-3'
              />
              <Image
                src={
                  process.env.REACT_APP_S3_ASSETS +
                  'plant-and-food-research.png'
                }
                alt='Plant &amp; Food Research'
                height='50'
                className='m-3'
              />
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'waikato-university.png'}
                alt='Waikato University'
                height='50'
                className='m-3'
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Credits;
