import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { getProductionAreas } from '../reportUtils';
import ProductionArea from './ProductionArea';

const ProductionAreas = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;
  const blocks = property?.property_blocks;

  const productionAreas = getProductionAreas(
    userPropertyStore,
    property,
    blocks
  );

  return (
    <>
      {productionAreas.map((area, i) => (
        <ProductionArea key={i} productionArea={area} />
      ))}
    </>
  );
};

export default observer(ProductionAreas);
