import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import Heading from '../Heading';
import { Action, ActionKind } from '../utils/riskAssessmentReducer';

interface Props {
  forecastImpact: string;
  dispatch: React.Dispatch<Action>;
}

const RiskAssessmentForecast = ({ forecastImpact, dispatch }: Props) => {
  const textboxChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch({
        type: ActionKind.SET_FORECAST_IMPACT,
        payload: e.target.value
      });
    },
    [dispatch]
  );

  const debounceChange = useMemo(
    () => debounce(textboxChange, 1000),
    [textboxChange]
  );

  return (
    <>
      <Heading className='mb-3' level='h5'>
        Expected Weather Forecast &amp; Impact
      </Heading>
      <Alert className='mb-3' variant='primary'>
        What impact will weather have on your actions? Please include
        consideration for drift, rainfall and other factors.
      </Alert>
      <textarea
        ref={(input) => {
          if (input) input.value = forecastImpact;
        }}
        className='form-control'
        rows={8}
        onChange={debounceChange}
        defaultValue={forecastImpact || ''}
      />
    </>
  );
};

export default React.memo(RiskAssessmentForecast);
