import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useReducer } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { useParams } from 'react-router';
import { PropertyBlock } from '../../../../../models/userTypes';
import { UserPropertyContext } from '../../../../../stores/StoreContexts';
import { ApiPost } from '../../../../../utils/utils';
import { errorToast } from '../../../../helpers/toasts/ToastUtils';
import { filterDuplicateUpdates, reducer } from '../utils/sprayPlanCardReducer';
import SprayPlanCardForm from './SprayPlanCardForm';

interface Props {
  productionArea: PropertyBlock | undefined;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const SprayPlanCardEdit = ({
  productionArea,
  setEditing
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [state, dispatch] = useReducer(reducer, { updates: [] });
  const dispatchMemoized = useCallback(dispatch, [dispatch]);
  const { propertyId, productionId }: any = useParams();

  const block_sprays = productionArea?.block_sprays
    ? productionArea.block_sprays
    : [];

  const submitUpdates = async () => {
    const newState = filterDuplicateUpdates(state);
    const res: Response = await ApiPost(
      `property/${propertyId}/production-area/${productionId}/block-spray/batch`,
      newState.updates
    );
    if (res.ok) {
      await userPropertyStore.getProductionAreaDetails(propertyId);
    } else {
      errorToast('Something went wrong');
    }
    setEditing(false);
  };

  return (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>2. Spray Product List</h5>
        <div>
          <>
            <Button
              variant='link'
              className='text-success'
              onClick={() => submitUpdates()}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              variant='link'
              className='text-danger'
              type='button'
              onClick={() => setEditing(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </>
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          className='rounded-0 text-dark mt-1'
          variant='info'
          style={{ marginTop: 10 }}
        >
          Add the spray product, the purpose, the number of applications and
          aproximate timing. Create a list of all the spray products you apply
          on this production area, including the typical or expected number of
          applications in a season, the target(s) of the applications and the
          timing. The timing can be specific or a rough estimate e.g. spring,
          after wetness events, etc.
        </Alert>
        {block_sprays.map((spray, index) => {
          return (
            <SprayPlanCardForm
              key={spray.id}
              spray={spray}
              state={state}
              dispatch={dispatchMemoized}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default observer(SprayPlanCardEdit);
