import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { UserProperty } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';

export interface Region {
  id?: string;
  name?: string;
  created_at: string;
  updated_at: string;
}

function PropertyInfoForm(
  props: FormikProps<{
    selectedProperty: UserProperty;
  }>
) {
  const userPropertyStore = useContext(UserPropertyContext);

  const { errors, touched } = props;

  const validatePropertyDetail = (value: string) => {
    let error;
    if (!value || value === '') {
      error = "Field can't be empty.";
    }
    return error;
  };

  return (
    <>
      {/* Property details forms */}
      <div className='my-2'>
        <b>Property Details</b>
      </div>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>
            ID/Number <span className='text-danger'>*</span>{' '}
          </Form.Label>
          <Field
            name='selectedProperty.property_id'
            as='input'
            className='form-control'
            validate={validatePropertyDetail}
          />
          {errors.selectedProperty?.property_id &&
          touched.selectedProperty?.property_id ? (
            <div className='text-danger'>
              {errors.selectedProperty?.property_id}
            </div>
          ) : (
            <></>
          )}
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>
            Name <span className='text-danger'>*</span>
          </Form.Label>
          <Field
            name='selectedProperty.property_name'
            as='input'
            className='form-control'
            validate={validatePropertyDetail}
          />
          {errors.selectedProperty?.property_name &&
          touched.selectedProperty?.property_name ? (
            <div className='text-danger'>
              {errors.selectedProperty?.property_name}
            </div>
          ) : (
            <></>
          )}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>
            Region <span className='text-danger'>*</span>
          </Form.Label>
          <Field
            name='selectedProperty.region_id'
            as='select'
            className='form-control'
          >
            {userPropertyStore.regions?.map((region: Region) => (
              <option key={`region_${region.name}`} value={region.id}>
                {region.name}
              </option>
            ))}
          </Field>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>
            Address <span className='text-danger'>*</span>
          </Form.Label>
          <Field
            name='selectedProperty.address'
            as='textarea'
            className='form-control'
            validate={validatePropertyDetail}
          />
          {errors.selectedProperty?.address &&
          touched.selectedProperty?.address ? (
            <div className='text-danger'>
              {errors.selectedProperty?.address}
            </div>
          ) : (
            <></>
          )}
        </Form.Group>
      </Row>

      {/* Owner details forms */}
      <div className='my-2'>
        <b>Owner Details</b>
      </div>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <Field
            name='selectedProperty.owner_name'
            as='input'
            className='form-control'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <Field
            name='selectedProperty.owner_email'
            as='input'
            className='form-control'
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <Field
            name='selectedProperty.owner_phone'
            as='input'
            className='form-control'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Mobile</Form.Label>
          <Field
            name='selectedProperty.owner_mobile'
            as='input'
            className='form-control'
          />
        </Form.Group>
      </Row>

      {/* Manager Details forms*/}
      <div className='my-2'>
        <b>Manager Details</b>
      </div>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <Field
            name='selectedProperty.manager_name'
            as='input'
            className='form-control'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <Field
            name='selectedProperty.manager_email'
            as='input'
            className='form-control'
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <Field
            name='selectedProperty.manager_phone'
            as='input'
            className='form-control'
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Mobile</Form.Label>
          <Field
            name='selectedProperty.manager_mobile'
            as='input'
            className='form-control'
          />
        </Form.Group>
      </Row>
    </>
  );
}

export default observer(PropertyInfoForm);
