import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'react-select-virtualized';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import {
  Action,
  ActionKind
} from '../../production_areas/production_cards/utils/copySpraysReducer';

interface Option {
  label: string;
  value: PropertyBlock;
}

interface Props {
  currentProductionArea: PropertyBlock | undefined;
  newProductionArea: PropertyBlock | null;
  property: UserProperty | null;
  dispatch: React.Dispatch<Action>;
}

const ProductionAreaSelect = ({
  currentProductionArea,
  newProductionArea,
  property,
  dispatch
}: Props): JSX.Element => {
  const initialValue = newProductionArea
    ? ({
        label: newProductionArea?.name,
        value: newProductionArea
      } as Option)
    : null;
  const userPropertyStore = useContext(UserPropertyContext);
  const [value, setValue] = useState<Option | null>(initialValue);
  const options: Option[] | undefined = property?.property_blocks
    ?.map((block: PropertyBlock) => {
      return {
        label: block.name || '',
        value: block
      };
    })
    .filter((option) => option.value.id !== currentProductionArea?.id);

  useEffect(() => {
    setValue((value) => (!newProductionArea ? null : value));
  }, [newProductionArea]);

  const isDisabled = () => {
    if (value !== null) return false;
    const selectedProperty = userPropertyStore.selectedProperty;
    // Checking if this select should be disabled can be improved
    // with a loading state for the 'getProductionAreaDetails' method.
    if (selectedProperty?.property_blocks) {
      return !property;
    }
    return true;
  };

  return (
    <Select
      options={options ? options : []}
      placeholder='Select a Production Area'
      value={value}
      onChange={async (v: Option) => {
        setValue(v);
        dispatch({
          type: ActionKind.SET_PRODUCTION_AREA,
          payload: v?.value || null
        });
        await userPropertyStore.getProducts();
      }}
      isDisabled={isDisabled()}
    />
  );
};

export default observer(ProductionAreaSelect);
