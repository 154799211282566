import { faCheck, faEdit, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Production } from '../../../models/propertyTypes';
import PropertyStore from '../../../stores/PropertyStore';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import LoadingOverlay from '../../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import { productionAreaSchema } from '../../validation_schemas/FormSchemas';

const ProductionInfoCard = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const [editing, setEditing] = useState(false);
  let { propertyId, productionId }: any = useParams();
  let productionArea = userPropertyStore.properties
    ?.filter((property) => property.id === Number(propertyId))[0]
    ?.property_blocks!.filter((area) => area.id === Number(productionId))[0];

  return editing ? (
    <Formik
      initialValues={{ ...productionArea }}
      enableReinitialize
      validationSchema={productionAreaSchema}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        ApiPut(`property/${propertyId}/production-area/${productionId}`, data)
          .then((res) => {
            if (res.ok) userPropertyStore.getProductionAreaDetails(propertyId);
            else {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            setSubmitting(false);
            setEditing(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <h5 className='m-0'>1. Basic Production Area Information</h5>
              <div>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      variant='link'
                      className='text-success'
                      type='submit'
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button
                      variant='link'
                      className='text-danger'
                      type='button'
                      disabled={props.isSubmitting}
                      onClick={() => setEditing(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Production Type / Land Use{' '}
                    <span className='text-danger'>*</span>
                  </Form.Label>
                  <Field
                    name='production_id'
                    as='select'
                    className='form-control'
                  >
                    <option value=''>-- Select a type --</option>
                    {userPropertyStore.productions?.map((type: Production) => (
                      <option key={`type_${type.type}`} value={type.id}>
                        {type.type}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='production_id' />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    Production Area Name <span className='text-danger'>*</span>
                  </Form.Label>
                  <Field name='name' as='input' className='form-control' />
                  <ErrorMessage name='name' />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Land Area (Hectares)</Form.Label>
                  <Field name='area' as='input' className='form-control' />
                  <ErrorMessage name='area' />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Review Date</Form.Label>
                  <Field
                    name='review_date'
                    type='date'
                    className='form-control'
                  />
                  <ErrorMessage name='review_date' />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>1. Basic Production Area Information</h5>
        <Button
          variant='link'
          type='button'
          onClick={() => setEditing(!editing)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row>
            {/* Property details column */}
            <Col>
              <Row>
                <span>
                  Production Type / Land Use:{' '}
                  {userPropertyStore.productions?.filter(
                    (production) =>
                      production.id === productionArea?.production_id
                  )[0]?.type || <i style={{ color: 'gray' }}>(blank)</i>}
                </span>
              </Row>
              <Row>
                <span>
                  Production Area Name:{' '}
                  {productionArea?.name || (
                    <i style={{ color: 'gray' }}>(blank)</i>
                  )}
                </span>
              </Row>
              <Row>
                <span>
                  Land Area (Hectares):{' '}
                  {productionArea?.area
                    ? `${productionArea.area} (ha)`
                    : '' || <i style={{ color: 'gray' }}>(blank)</i>}
                </span>
              </Row>
              <Row>
                <span>
                  Review Date :{' '}
                  {(productionArea?.review_date &&
                    new Date(productionArea.review_date).toLocaleDateString(
                      'en-NZ',
                      {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      }
                    )) || <i style={{ color: 'gray' }}>(blank)</i>}
                </span>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default observer(ProductionInfoCard);
