import React from 'react';
import { BlockSpray } from '../../../../../models/userTypes';

export interface PUTUpdate {
  action: 'PUT';
  data: BlockSpray;
}

export interface DELETEUpdate {
  action: 'DELETE';
  id: number;
}

export type Update = PUTUpdate | DELETEUpdate;

export interface State {
  updates: Update[];
}

export enum ActionKind {
  PUSH_UPDATE = 'PUSH_UPDATE',
  EDIT_UPDATE = 'EDIT_UPDATE',
  REMOVE_DELETE_UPDATE = 'REMOVE_DELETE_UPDATE'
}

export type Action = { type: ActionKind; payload: Update };

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionKind.PUSH_UPDATE: {
      const updates = state.updates;
      state.updates = [...updates, payload];

      return state;
    }
    case ActionKind.EDIT_UPDATE: {
      let updates = state.updates;
      updates = updates.map((update) => {
        if (update.action === 'PUT' && payload.action === 'PUT') {
          if (update.data.id === payload.data.id) {
            return payload;
          }
        }
        return update;
      });
      state.updates = updates;
      return state;
    }
    case ActionKind.REMOVE_DELETE_UPDATE: {
      if (payload.action === 'DELETE') {
        const index = state.updates.findIndex(
          (update) => update.action === 'DELETE' && update.id === payload.id
        );
        if (index > -1) state.updates.splice(index, 1);
      }
      return state;
    }
    default:
      return state;
  }
};

export const putUpdateDispatch = <T>(
  state: State,
  dispatch: React.Dispatch<Action>,
  spray: BlockSpray,
  property: string,
  value: T
) => {
  const exists = state.updates.find(
    (update) => update.action === 'PUT' && update.data.id === spray.id
  ) as PUTUpdate;
  if (exists) {
    dispatch({
      type: ActionKind.EDIT_UPDATE,
      payload: {
        action: 'PUT',
        data: { ...exists.data, [property]: value }
      }
    });
  } else {
    dispatch({
      type: ActionKind.PUSH_UPDATE,
      payload: {
        action: 'PUT',
        data: { ...spray, [property]: value }
      }
    });
  }
};

export const deleteUpdateDispatch = (
  state: State,
  dispatch: React.Dispatch<Action>,
  spray: BlockSpray
) => {
  const exists = state.updates.find(
    (update) => update.action === 'DELETE' && update.id === spray.id
  ) as DELETEUpdate;
  if (exists) {
    dispatch({
      type: ActionKind.REMOVE_DELETE_UPDATE,
      payload: {
        action: 'DELETE',
        id: exists.id
      }
    });
  } else {
    dispatch({
      type: ActionKind.PUSH_UPDATE,
      payload: {
        action: 'DELETE',
        id: spray.id
      }
    });
  }
};

/**
 * It's possible to submit the form with the same spray having
 * both a put and delete updates. Because of this, we need to
 * check for duplicate sprays in the updates array, and discard
 * the PUT update for a spray if it also has a DELETE. This
 * means the DELETE update takes precedence over PUT.
 *
 * @param state
 */
export const filterDuplicateUpdates = (state: State) => {
  const updates = state.updates;
  let newUpdates: (Update | null)[] = updates;
  const indexesToRemove: number[] = [];
  // Find indexes that have duplicate updates;
  updates.forEach((update, index) => {
    updates.forEach((_update, _index) => {
      if (index === _index) return;
      if (_update.action === 'PUT' && update.action === 'PUT') return;
      if (_update.action === 'DELETE' && update.action === 'DELETE') return;

      if (_update.action === 'PUT' && update.action === 'DELETE') {
        if (_update.data.id === update.id && !indexesToRemove.includes(_index))
          indexesToRemove.push(_index);
      }

      if (_update.action === 'DELETE' && update.action === 'PUT') {
        if (_update.id === update.data.id && !indexesToRemove.includes(index))
          indexesToRemove.push(index);
      }
    });
  });
  // Filter duplicate updates;
  indexesToRemove.forEach((index) => (newUpdates[index] = null));
  newUpdates = newUpdates.filter((nu) => nu !== null);
  state.updates = newUpdates as Update[];

  return state;
};
