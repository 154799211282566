import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Alert, Button, Form as rbForm, Modal } from 'react-bootstrap';
import { Feature } from '../../models/types';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPut } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import { propertyPlaceSchema } from '../validation_schemas/FormSchemas';

export interface NewPropertyPlace {
  id?: number;
  icon: string;
  type: string;
  name: string;
  description: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  propertyPlaceToEdit: Feature | null;
};

const EditPropertyPlace = ({
  showModal,
  setShowModal,
  propertyPlaceToEdit
}: Props): JSX.Element => {
  // TODO: Need to add the 'test' fields to the form
  const initialValues: NewPropertyPlace = {
    icon: 'test',
    type: 'test',
    name: propertyPlaceToEdit?.properties.name || '',
    description: 'test'
  };
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Edit marker
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={propertyPlaceSchema}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            const feature: Feature = {
              ...propertyPlaceToEdit,
              properties: { ...propertyPlaceToEdit?.properties, ...data }
            } as Feature;
            const res = await ApiPut(
              `property/${userPropertyStore.selectedPropertyId}/property-place/${feature.properties.id}`,
              feature as any
            );
            if (res && res.ok) {
              userPropertyStore.getPlaces();
            } else {
              errorToast('Something went wrong');
            }
            setSubmitting(false);
            setShowModal(false);
          }}
        >
          {(props) => (
            <Form className='inner' id='register-form'>
              <Modal.Body>
                <Alert
                  className='rounded-0 text-dark mt-1'
                  variant='secondary'
                  style={{ marginTop: 10 }}
                >
                  Add a new marker to&nbsp;
                  <b>{userPropertyStore.selectedProperty?.property_name}</b>
                </Alert>

                {/* <rbForm.Group>
                  <label>Icon</label>
                  <Field
                    name='icon'
                    type='input'
                    placeholder=''
                    className='form-control'
                    required
                  />
                  <ErrorMessage name='icon' />
                </rbForm.Group>
                <rbForm.Group>
                  <label>Type</label>
                  <Field
                    name='type'
                    type='input'
                    placeholder=''
                    className='form-control'
                    required
                  />
                  <ErrorMessage name='type' />
                </rbForm.Group> */}
                <rbForm.Group>
                  <label>Name</label>
                  <Field
                    name='name'
                    type='input'
                    className='form-control'
                    autoComplete='off'
                    required
                  />
                  <ErrorMessage name='name' />
                </rbForm.Group>
                {/* <rbForm.Group>
                  <label>Description</label>
                  <Field
                    name='description'
                    type='input'
                    placeholder=''
                    className='form-control'
                    autocomplete='off'
                    required
                  />
                  <ErrorMessage name='description' />
                </rbForm.Group> */}
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Edit Marker
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(EditPropertyPlace);
