import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

type Props = {
  hideOpacity?: boolean;
  color?: string;
  opacity?: number;
  style?: any;
};

const LoadingOverlay = ({
  hideOpacity,
  color,
  opacity,
  style
}: Props): JSX.Element => {
  const _style: any = {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    ...style
  };

  if (!hideOpacity) {
    _style.backgroundColor = color ? color : 'white';
    _style.opacity = opacity ? opacity : 0.8;
  }

  return (
    <div style={_style}>
      <Spinner animation='border' variant='primary' />
    </div>
  );
};

LoadingOverlay.propTypes = {
  hideOpacity: PropTypes.bool
};

export default LoadingOverlay;
