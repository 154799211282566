import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import AddProductionArea from '../../modals/AddProductionArea';
import './property_cards.scss';

const PropertyCard = (): JSX.Element => {
  // Production viewer card should take an object, and display relevent information
  const userPropertyStore = useContext(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;

  const [addProductionArea, setAddProductionArea] = useState(false);

  const headerColour = userPropertyStore
    .missingSprayPlanComponents(property)
    .includes('Production areas')
    ? 'alert-warning'
    : '';

  return (
    <>
      <Card id='productionareas' border='muted'>
        <Card.Header
          className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
        >
          <h5 className='m-0'>6. Production Areas</h5>
        </Card.Header>
        <Card.Body>
          <Alert
            className='rounded-0 text-dark mt-1'
            variant='info'
            style={{ marginTop: 10 }}
          >
            A production area can be defined however you like, e.g. by paddock,
            variety, or spray programme.
          </Alert>
          <Row>
            <Col>
              <Container>
                <div>
                  <ul className='list-unstyled'>
                    {userPropertyStore.selectedProperty?.property_blocks
                      ?.length === 0 ||
                    undefined ||
                    null ? (
                      <p className='empty-card-text'>
                        No production areas added
                      </p>
                    ) : (
                      userPropertyStore.selectedProperty?.property_blocks?.map(
                        (area) => (
                          <li key={area.id}>
                            <Link
                              to={`/production-area/${userPropertyStore.selectedPropertyId}/${area.id}`}
                            >
                              {area.name}
                            </Link>
                          </li>
                        )
                      )
                    )}
                  </ul>
                </div>
                <div className='add-button'>
                  <Button
                    variant='outline-success'
                    onClick={() => setAddProductionArea(!addProductionArea)}
                    style={{ textAlign: 'center' }}
                  >
                    Add Production Area
                  </Button>
                </div>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddProductionArea
        showModal={addProductionArea}
        setShowModal={setAddProductionArea}
        selectedPropertyID={userPropertyStore.selectedPropertyId || 0}
      />
    </>
  );
};

export default observer(PropertyCard);
