import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BlockHazard } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import LoadingButton from '../../../helpers/buttons/LoadingButton';
import FormField from '../../../helpers/forms/FormField';
import { deleteHazard } from '../HazardsCard';

function HazardForm(
  props: FormikProps<{ block_hazards: BlockHazard[] }> & {
    propertyId: number;
    productionId: number;
  }
) {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      {props.values.block_hazards.map((hazard, index) => (
        <Row key={hazard.id}>
          <FormField
            fieldName={`block_hazards[${index}].description`}
            fieldAs='input'
            message='A description of the hazard'
            props={props}
          />
          <FormField
            fieldName={`block_hazards[${index}].management`}
            fieldAs='input'
            message='Actions you will take to manage the hazard'
            props={props}
          />
          <Col style={{ flexGrow: 0.15 }}>
            <LoadingButton
              variant='outline-danger link'
              onClick={() =>
                deleteHazard(
                  hazard.id,
                  props.propertyId,
                  props.productionId,
                  userPropertyStore
                )
              }
              text='Delete'
            />
          </Col>
        </Row>
      ))}
      <hr></hr>
    </>
  );
}

export default observer(HazardForm);
