import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';

const Neighbours = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const neighbours = userPropertyStore.selectedProperty?.property_neighbours
    ? userPropertyStore.selectedProperty?.property_neighbours
    : [];

  return (
    <div>
      <ul className='list-unstyled'>
        {neighbours?.length === 0 || undefined || null ? (
          <p className='empty-card-text'>No neighbours</p>
        ) : (
          <Table striped hover size='sm' responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {neighbours?.map((neighbours, i) => (
                <tr key={i}>
                  <td>
                    {neighbours.name || (
                      <i style={{ color: 'gray' }}>(blank name)</i>
                    )}
                  </td>
                  <td>
                    {neighbours.address || (
                      <i style={{ color: 'gray' }}>(blank address)</i>
                    )}
                  </td>
                  <td>
                    {neighbours.phone || (
                      <i style={{ color: 'gray' }}>(blank phone)</i>
                    )}
                  </td>
                  <td>
                    {neighbours.email || (
                      <i style={{ color: 'gray' }}>(blank email)</i>
                    )}
                  </td>
                </tr>
              )) || <tr></tr>}
            </tbody>
          </Table>
        )}
      </ul>
    </div>
  );
};

export default Neighbours;
