import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select-virtualized';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { Action, ActionKind } from '../utils/riskAssessmentReducer';

interface Option {
  label: string;
  value: PropertyBlock;
}

interface Props {
  productionArea: PropertyBlock | null;
  property: UserProperty | null;
  dispatch: React.Dispatch<Action>;
}

const ProductionAreaSelect = ({
  productionArea,
  property,
  dispatch
}: Props): JSX.Element => {
  const initialValue = productionArea
    ? ({
        label: productionArea?.name,
        value: productionArea
      } as Option)
    : null;
  const userPropertyStore = useContext(UserPropertyContext);
  const [value, setValue] = useState<Option | null>(initialValue);
  const options: Option[] | undefined = property?.property_blocks?.map(
    (block: PropertyBlock) => {
      return {
        label: block.name || '',
        value: block
      };
    }
  );

  useEffect(() => {
    setValue((value) => (!productionArea ? null : value));
  }, [productionArea]);

  const isDisabled = () => {
    if (value !== null) return false;
    const selectedProperty = userPropertyStore.selectedProperty;
    // Checking if this select should be disabled can be improved
    // with a loading state for the 'getProductionAreaDetails' method.
    if (selectedProperty?.property_blocks) {
      const hasHazards = selectedProperty?.property_blocks[0]?.block_hazards;
      const hasSprays = selectedProperty?.property_blocks[0]?.block_sprays;
      return !property || !hasHazards || !hasSprays;
    }
    return true;
  };

  return (
    <Select
      options={options ? options : []}
      placeholder='Select a Production Area'
      value={value}
      onChange={async (v: Option | null) => {
        if (v === null) return;
        setValue(v);
        dispatch({
          type: ActionKind.SET_PRODUCTION_AREA,
          payload: v.value
        });
        await userPropertyStore.getProducts();
      }}
      isDisabled={isDisabled()}
    />
  );
};

export default observer(ProductionAreaSelect);
