import { useCallback, useState } from 'react';
import { FormLabel, OverlayTrigger } from 'react-bootstrap';
import { BlockSpray } from '../../../../../models/userTypes';
import {
  Action,
  putUpdateDispatch,
  State
} from '../utils/sprayPlanCardReducer';
import { renderTooltip } from './utils/renderTooltip';

interface Props {
  spray: BlockSpray;
  property: string;
  label: string;
  value: string | number;
  message: string;
  state: State;
  dispatch: React.Dispatch<Action>;
}

const SprayPlanCardInput = ({
  spray,
  property,
  label,
  value,
  message,
  state,
  dispatch
}: Props) => {
  const [inputValue, setInputValue] = useState(value);

  const inputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      putUpdateDispatch(state, dispatch, spray, property, e.target.value);
      setInputValue(e.target.value);
    },
    [dispatch, property, spray, state]
  );

  return (
    <div style={{ width: '100%' }}>
      <OverlayTrigger
        placement='right'
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, message)}
      >
        <FormLabel>
          <b>{label}</b>
        </FormLabel>
      </OverlayTrigger>

      <input
        id={property}
        className='form-control'
        name={property}
        type='text'
        value={inputValue || ''}
        onChange={inputChange}
      />
    </div>
  );
};

export default SprayPlanCardInput;
