import { Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

export const renderTooltip = (
  props: OverlayInjectedProps,
  message: string
): JSX.Element => (
  <Tooltip id='tooltip' {...props}>
    {message}
  </Tooltip>
);
