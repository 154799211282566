import { createContext } from 'react';
import PropertyStore from '../stores/PropertyStore';
import UserStore from '../stores/UserStore';

const userStore = new UserStore();
export const UserContext = createContext<UserStore>(userStore);

const userPropertyStore = new PropertyStore(userStore);
export const UserPropertyContext =
  createContext<PropertyStore>(userPropertyStore);
