import { Col, Container, Image, Row } from 'react-bootstrap';
import './sponsors.scss';

/**
 * To add a new sponsor:
 * 1. Add a new entry to the `sponsors` array below
 * 2. Add the sponsor's logos to the S3 assets bucket
 * 3. Add any custom styling needed in sponsors.scss
 *
 * Each sponsor requires:
 * - href: URL to sponsor's website
 * - logoDark: Filename of dark theme logo in S3 bucket
 * - logoLight: Filename of light theme logo in S3 bucket
 * - alt: Alt text for the logo image
 * - className: Optional CSS class name for custom styling
 */

type SponsorsProps = {
  mode?: 'header' | 'homepage' | 'report';
};

type SponsorData = {
  href: string;
  logoDark: string;
  logoLight: string;
  alt: string;
  className?: string;
};

const sponsors: SponsorData[] = [
  {
    href: 'https://croplands.com/nz/',
    logoDark: 'croplands-logo.svg',
    logoLight: 'croplands-logo.svg',
    alt: 'Croplands Logo',
    className: 'croplands-logo'
  },
  {
    href: 'https://www.nzwine.com/',
    logoDark: 'nzwine-logo-dark.svg',
    logoLight: 'nzwine-logo-light.svg',
    alt: 'NZ Wine Logo',
    className: 'nzwine-logo'
  }
];

const Sponsors = ({ mode }: SponsorsProps): JSX.Element => {
  const renderSponsorLink = (
    sponsor: SponsorData,
    isLight?: boolean,
    height?: string
  ) => {
    const logo = isLight ? sponsor.logoLight : sponsor.logoDark;

    return (
      <a
        href={sponsor.href}
        target='_blank'
        rel='noopener noreferrer'
        className={mode === 'header' ? 'sp-nav-inner' : undefined}
      >
        <Image
          src={process.env.REACT_APP_S3_ASSETS + logo}
          alt={sponsor.alt}
          height={height}
          className={height ? 'm-3' : sponsor.className} // if no default classname, add margin
        />
      </a>
    );
  };

  switch (mode) {
    case 'homepage':
      return (
        <Container fluid className='my-4'>
          <hr />
          <Container className='pt-3'>
            <Row className='justify-content-center align-items-center'>
              <Col className='text-center' xs={5} md={4}>
                <h3 className='float-left font-weight-bold'>Supported By</h3>
              </Col>
              <Col>
                <Row className='justify-content-center align-items-center'>
                  {sponsors.map((sponsor, index) => (
                    <Col key={index} className='text-center'>
                      {renderSponsorLink(
                        sponsor,
                        false,
                        index === 0 ? '20' : '50'
                      )}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    case 'header':
      return (
        <div className='header-sponsorship-section'>
          <div className='sponsorship-logos'>
            <span className='sponsorship-text'>Supported By</span>
            {sponsors.map((sponsor, index) => renderSponsorLink(sponsor, true))}
          </div>
        </div>
      );
    case 'report':
      return (
        <div className='report-sponsorship-section'>
          <hr />
          <div className='sponsorship-text'>
            SprayPlan Manager is Proudly Supported By:
          </div>
          <div className='sponsorship-logos'>
            {sponsors.map((sponsor) => renderSponsorLink(sponsor, false))}
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default Sponsors;
