import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import PropertyStore from '../../../stores/PropertyStore';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import Table from './Table';
import './tables.scss';
import { makeData } from './utils';

const ETTable = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const productHSNOs = userPropertyStore.productHSNOs;

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'blank-0',
        columns: [
          {
            Header: '',
            id: 'blank-0-0',
            columns: [
              {
                Header: '',
                id: 'blank-0-0-0',
                columns: [
                  {
                    Header: 'Product',
                    id: 'product',
                    accessor: 'product'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '',
        id: 'blank-1',
        columns: [
          {
            Header: '',
            id: 'blank-1-1',
            columns: [
              {
                Header: 'Tracking controls apply',
                columns: [
                  {
                    Header: '',
                    id: 'blank-1-1-1',
                    accessor: 'tracking'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '',
        id: 'blank-2',
        columns: [
          {
            Header: '',
            id: 'blank-2-2',
            columns: [
              {
                Header: 'Approved handler controls apply',
                columns: [
                  {
                    Header: '',
                    id: 'blank-2-2-2',
                    accessor: 'approval'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '9.1',
        columns: [
          {
            Header: 'Water',
            columns: [
              {
                Header:
                  'May cause long lasting harmful effects to aquatic life',
                columns: [
                  {
                    Header: 'D',
                    id: 'D-0',
                    accessor: '91xAquaticxD'
                  }
                ]
              },
              {
                Header: 'Harmful to aquatic life with long lasting effects',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-0',
                    accessor: '91xAquaticxC'
                  }
                ]
              },
              {
                Header: 'Toxic to aquatic life with long lasting effect',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-0',
                    accessor: '91xAquaticxB'
                  }
                ]
              },
              {
                Header: 'Very toxic to aquatic life with long lasting effects',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-0',
                    accessor: '91xAquaticxD'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '9.2',
        columns: [
          {
            Header: 'Soil',
            columns: [
              {
                Header: 'Harmful to the soil environment',
                columns: [
                  {
                    Header: 'D',
                    id: 'D-1',
                    accessor: '92xSoilxD'
                  }
                ]
              },
              {
                Header: 'Harmful to the soil environment-',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-1',
                    accessor: '92xSoilxC'
                  }
                ]
              },
              {
                Header: 'Toxic to the soil environment',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-1',
                    accessor: '92xSoilxB'
                  }
                ]
              },
              {
                Header: 'Very toxic to the soil environment',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-1',
                    accessor: '92xSoilxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '9.3',
        columns: [
          {
            Header: 'Birds & Animals',
            columns: [
              {
                Header: 'Harmful to terrestrial vertebrates',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-2',
                    accessor: '93xTerrestrial VertebratesxC'
                  }
                ]
              },
              {
                Header: 'Toxic to terrestrial vertebrates',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-2',
                    accessor: '93xTerrestrial VertebratesxB'
                  }
                ]
              },
              {
                Header: 'Very toxic to terrestrial vertebrates',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-2',
                    accessor: '93xTerrestrial VertebratesxA'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Header: '9.4',
        columns: [
          {
            Header: 'Bees',
            columns: [
              {
                Header: 'Harmful to terrestrial invertebrates',
                columns: [
                  {
                    Header: 'C',
                    id: 'C-3',
                    accessor: '94xTerrestrial Invertebrates xC'
                  }
                ]
              },
              {
                Header: 'Toxic to terrestrial invertebrates',
                columns: [
                  {
                    Header: 'B',
                    id: 'B-3',
                    accessor: '94xTerrestrial Invertebrates xB'
                  }
                ]
              },
              {
                Header: 'Very toxic to terrestrial invertebrates',
                columns: [
                  {
                    Header: 'A',
                    id: 'A-3',
                    accessor: '94xTerrestrial Invertebrates xA'
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    []
  );

  const data = useMemo<readonly { [x: string]: {} }[]>(
    () => makeData(productHSNOs, 9),
    [productHSNOs]
  );

  return (
    <div className='ettable'>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default observer(ETTable);
