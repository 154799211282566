import React from 'react';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';
import RiskAssessmentSelectProducts from '../../modals/RiskAssessmentSelectProducts';
import Heading from '../Heading';
import { Action, ProductChecked } from '../utils/riskAssessmentReducer';

interface Props {
  products: ProductChecked[];
  productionArea: PropertyBlock | null;
  dispatch: React.Dispatch<Action>;
}

const RiskAssessmentProducts = ({
  products,
  productionArea,
  dispatch
}: Props): JSX.Element => {
  const noSelected = () => {
    return products.find((pc) => pc.checked) === undefined;
  };

  return (
    <>
      <Heading className='mb-3' level='h5'>
        What's In The Tank
      </Heading>
      <Alert className='mb-3' variant='primary'>
        Specify the products that are going to be applied.
      </Alert>
      <Row className='mb-3'>
        <Col>
          <RiskAssessmentSelectProducts
            products={products}
            productionArea={productionArea}
            dispatch={dispatch}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          {noSelected() ? (
            <p>No products selected</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Product Name</th>
                </tr>
              </thead>
              <tbody>
                {products.map((productsChecked, i) => {
                  const products = productsChecked.product;
                  const checked = productsChecked.checked;
                  if (!checked) return null;
                  return (
                    <tr key={i}>
                      <td>{products?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default React.memo(RiskAssessmentProducts);
