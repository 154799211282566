import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { PropertyPersonnel } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import LoadingButton from '../../../helpers/buttons/LoadingButton';
import FormField from '../../../helpers/forms/FormField';
import AddCertificate from '../../../modals/AddCertificate';
import { DeleteCertification, DeletePersonnel } from '../PropertyCardUtils';

function PersonnelForm(
  props: FormikProps<{
    property_personnel: PropertyPersonnel[];
  }>
) {
  const userPropertyStore = useContext(UserPropertyContext);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.values.property_personnel?.map((person, index) => (
        <>
          <Row key={person.id}>
            <Col>
              <h5>Operator</h5>
            </Col>
            <Col>
              <div>
                <LoadingButton
                  style={{ float: 'right' }}
                  variant='outline-danger link'
                  onClick={() => DeletePersonnel(userPropertyStore, person.id)}
                  text='Delete'
                />
              </div>
            </Col>
          </Row>
          <Row>
            <FormField
              label='Name'
              fieldName={`property_personnel[${index}].name`}
              fieldAs='input'
              message='Their full name'
              props={props}
            />
            <FormField
              label='Phone'
              fieldName={`property_personnel[${index}].phone`}
              fieldAs='input'
              message='Their contact number'
              props={props}
            />
          </Row>
          <Row>
            <FormField
              label='Email'
              fieldName={`property_personnel[${index}].email`}
              fieldAs='input'
              message='Their email address'
              type='email'
              props={props}
            />
            <Form.Group as={Col}></Form.Group>
          </Row>
          <Container className='my-3'>
            <Row>
              <h5>Certifications</h5>
            </Row>
          </Container>
          {person.personnel_certifications?.map((certificate, i) => (
            <>
              <Row key={certificate.id}>
                <Form.Group as={Col}>
                  {index === 0 ? <b>Certificate</b> : null}
                  <Field
                    name={`property_personnel[${index}].personnel_certifications[${i}].name`}
                    as='input'
                    className='form-control'
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  {index === 0 ? <b>Certificate (e.g. Growsafe)</b> : null}
                  <Field
                    name={`property_personnel[${index}].personnel_certifications[${i}].number`}
                    as='input'
                    className='form-control'
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  {index === 0 ? <b>Expiry Date</b> : null}
                  <Field
                    name={`property_personnel[${index}].personnel_certifications[${i}].expire`}
                    type='date'
                    className='form-control'
                  />
                </Form.Group>
                <Form.Group>
                  <b>&nbsp;</b>
                  <div>
                    <LoadingButton
                      variant='outline-danger link'
                      onClick={() =>
                        DeleteCertification(
                          userPropertyStore,
                          person.id,
                          certificate.id
                        )
                      }
                      text='Delete'
                    />{' '}
                  </div>
                </Form.Group>
              </Row>
            </>
          ))}
          <Container>
            <Row>
              <Button
                variant='outline-success'
                onClick={() => setShowModal(true)}
              >
                Add item +
              </Button>
            </Row>
          </Container>
          <hr />
          <AddCertificate
            showModal={showModal}
            setShowModal={setShowModal}
            personId={person.id}
          />
        </>
      ))}
    </>
  );
}

export default observer(PersonnelForm);
