interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  level: React.ElementType;
}

const Heading = ({ children, className, level }: Props) => {
  const Heading = level;
  return (
    <div className={className}>
      <Heading className='d-inline'>{children}</Heading>
      <i className='ml-2'>(required)</i>
    </div>
  );
};

export default Heading;
