import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Feature } from '../../../models/types';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { useMultiBoundary } from '../../../utils/useMultiBoundary';
import PropertyImage from '../../properties/map/PropertyImage';
import PropertyMap from '../../properties/map/PropertyMap';
import {
  fitToBounds,
  satelliteStyle
} from '../../properties/map/PropertyMapUtils';

const RiskAssessmentMap = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [hasMap, setHasMap] = useState(false);

  const property = userPropertyStore.selectedProperty;
  const objectBoundaries = useMultiBoundary();
  const objectPropertyPlaces = property?.propertyPlaces as unknown as Feature[];
  const mapType = property?.map_type;

  useEffect(() => {
    const checkForMap = () => {
      if (property) {
        const image = property.property_image;
        const boundary = property.boundary;
        // Check if a map exists
        if (boundary?.type) {
          setHasMap(true);
        } else if (image && image[0]) {
          setHasMap(true);
        } else {
          setHasMap(false);
        }
      }
    };
    checkForMap();
  }, [property]);

  return (
    <>
      {hasMap && mapType === 'GIS' ? ( // Display map
        <PropertyMap
          compass
          enableEdit={false}
          initialStyle={satelliteStyle}
          consumerFunction={(map: any) => {
            fitToBounds(map, false, 20, [
              ...objectBoundaries,
              ...objectPropertyPlaces
            ]);
          }}
        />
      ) : hasMap && mapType === 'OWN' ? ( // Otherwise, display map image
        <PropertyImage setHasMap={setHasMap} deletable />
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(RiskAssessmentMap);
