import { orderBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  BasicSprayProduct,
  HSNO,
  HSNOIds,
  HSNOPivot,
  Method,
  Product,
  ProductHSNOs,
  Production,
  Region
} from '../models/propertyTypes';
import {
  BlockSpray,
  Boundary,
  PropertyBlock,
  PropertyImage,
  PropertyNeighbours,
  PropertyPersonnel,
  PropertyPlaces,
  PropertySensitives,
  UserProperty
} from '../models/userTypes';
import { ApiGet, ApiPutImage } from '../utils/utils';
import UserStore from './UserStore';

const GET_EVERYTHING_PATH: string =
  'properties?with[]=propertyPlaces&with[]=propertyNeighbours&with[]=propertySensitives&with[]=propertyPersonnel.personnelCertifications&with[]=propertyBlocks&with[]=propertyImage';

export default class PropertyStore {
  properties?: UserProperty[];
  selectedPropertyPlaces?: PropertyPlaces[];
  selectedPropertyId?: number;
  selectedProperty?: UserProperty;
  loading: boolean;
  userStore: UserStore;

  methods?: Method[];
  regions?: Region[];
  productions?: Production[];
  products?: BasicSprayProduct[];

  productHSNOs?: ProductHSNOs[];
  loadingHazardReport: boolean;
  loadingHsnoData: boolean;

  loadingProductionAreas: boolean;

  constructor(userStore: UserStore) {
    makeAutoObservable(this);
    this.loading = false;
    this.loadingHazardReport = false;
    this.loadingHsnoData = false;
    this.loadingProductionAreas = false;
    this.userStore = userStore;
  }

  //Gets all properties associated with logged in user
  async requestProperties() {
    this.loading = true;
    const res = await ApiGet(GET_EVERYTHING_PATH);
    if (res.ok) {
      const resx = await res.json();
      runInAction(() => {
        this.properties = resx as UserProperty[];
      });
    } else if (res.status === 401) {
      // Force logout
      this.userStore.logout(true);
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  setPropertyImage(property: UserProperty | undefined) {
    return new Promise((resolve) => {
      this.loading = true;
      if (property) {
        ApiGet(`property/${property.id}/map-image`)
          .then((res: Response) => {
            if (res.ok) {
              res.json().then((resx) =>
                runInAction(() => {
                  property.property_image = resx as PropertyImage[];
                  resolve(property.property_image);
                  this.getProperty();
                  this.loading = false;
                })
              );
            }
          })
          .finally(() => {
            runInAction(() => {
              this.loading = false;
            });
          });
      }
    });
  }

  getPropertyImage(property: UserProperty) {
    ApiGet(`property/${property.id}/map-image`)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((resx) =>
            runInAction(() => {
              property.property_image = resx as PropertyImage[];
              this.loading = false;
            })
          );
        }
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  //Gets all property places for the currently selected propertyID
  requestPropertyPlaces() {
    //this.loading = true;
    ApiGet('property/' + this.selectedPropertyId + '/property-places')
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) =>
                  (this.selectedPropertyPlaces = resx as PropertyPlaces[])
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() =>
        runInAction(() => {
          //this.loading = false;
        })
      );
  }

  async fetchBlockSprays(propertyId: number, productionAreaId: number) {
    const res = await ApiGet(
      `property/${propertyId}/production-area/${productionAreaId}/block-sprays`
    );
    if (res.ok) {
      const resx = (await res.json()) as BlockSpray[];
      const property = this.properties?.find(
        (property) => property.id === propertyId
      );
      const index = property?.property_blocks?.findIndex(
        (block) => block.id === productionAreaId
      );
      if (property && property.property_blocks && index !== undefined) {
        const prodArea = property.property_blocks[index];
        prodArea.block_sprays = resx;
        runInAction(() => {
          if (property.property_blocks) {
            property.property_blocks[index] = prodArea;
            this.selectedProperty = property;
          }
        });
      }
    } else if (res.status === 401) {
      // Force logout
      this.userStore.logout(true);
    }
  }

  clearProperties() {
    this.properties = undefined;
    this.selectedPropertyId = undefined;
    this.selectedProperty = undefined;
  }

  setSelectedProperty(propertyId: number, refresh?: boolean) {
    if (refresh) {
      this.requestProperties();
    }
    this.selectedPropertyId = propertyId;
    this.properties?.forEach((property) => {
      if (this.selectedPropertyId === property.id) {
        runInAction(() => {
          this.selectedProperty = property;
        });
      }
    });
  }

  setBoundary(boundary?: Boundary) {
    this.selectedProperty!.boundary = boundary;
  }

  setBoundaryIds(ids: string[]) {
    this.selectedProperty!.boundary_ids = ids;
  }

  setPropertyPlaces(propertyPlaces: PropertyPlaces[]) {
    runInAction(() => {
      this.selectedProperty!.propertyPlaces = propertyPlaces;
    });
  }

  setSensitives(propertySensitives: PropertySensitives[]) {
    runInAction(() => {
      this.selectedProperty!.property_sensitives = propertySensitives;
    });
  }

  setNeighbours(propertyNeibours: PropertyNeighbours[]) {
    runInAction(() => {
      this.selectedProperty!.property_neighbours = propertyNeibours;
    });
  }

  setPersonnel(propertyPersonnel: PropertyPersonnel[]) {
    runInAction(() => {
      this.selectedProperty!.property_personnel = propertyPersonnel;
    });
  }

  async setMapImage(data: FormData): Promise<Response> {
    this.loading = true;
    const res = await ApiPutImage(
      'property/' + this.selectedPropertyId + '/map-image',
      data
    );
    return res;
  }

  isSprayPlanReady(propertyId?: number): boolean {
    if (propertyId && this.properties) {
      let property = this.properties!.filter(
        (element) => element.id === propertyId
      );
      return (
        property[0]?.property_sensitives?.length !== 0 &&
        property[0]?.property_neighbours?.length !== 0 &&
        property[0]?.property_personnel?.length !== 0 &&
        property[0]?.property_blocks?.length !== 0
      );
    } else {
      if (
        this.selectedProperty?.property_sensitives &&
        this.selectedProperty?.property_neighbours &&
        this.selectedProperty?.property_personnel &&
        this.selectedProperty?.property_blocks
      ) {
        return (
          this.selectedProperty?.property_sensitives?.length > 0 &&
          this.selectedProperty?.property_neighbours?.length > 0 &&
          this.selectedProperty?.property_personnel?.length > 0 &&
          this.selectedProperty?.property_blocks?.length > 0
        );
      }
      return false;
    }
  }

  missingSprayPlanComponents(property: UserProperty | undefined): string[] {
    let missing: string[] = [];
    if (
      !property?.property_sensitives ||
      property?.property_sensitives?.length === 0
    ) {
      missing.push('Sensitive areas');
    }
    if (
      !property?.property_sensitives ||
      property?.property_neighbours?.length === 0
    ) {
      missing.push('Neighbours');
    }
    if (
      !property?.property_personnel ||
      property?.property_personnel?.length === 0
    ) {
      missing.push('Spray personnel');
    }
    if (!property?.property_blocks || property?.property_blocks?.length === 0) {
      missing.push('Production areas');
    }
    return missing;
  }

  getMethods() {
    if (this.methods == null || undefined) {
      ApiGet('methods').then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res.json().then((resx) => {
              const methods = resx as Method[];
              this.methods = orderBy(methods, [
                (method) => method.name.toLowerCase(),
                ['desc']
              ]) as Method[];
            });
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      });
    }
  }

  getRegions() {
    if (this.regions == null || undefined) {
      ApiGet('regions').then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res.json().then((resx) => (this.regions = resx as Region[]));
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      });
    }
  }

  getProductions() {
    if (this.productions == null || undefined) {
      ApiGet('productions').then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then((resx) => (this.productions = resx as Production[]));
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      });
    }
  }

  getProperty() {
    this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}?${GET_EVERYTHING_PATH}`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res.json().then((resx) => {
              this.selectedProperty! = resx as UserProperty;
              this.properties = this.properties?.map((property) => {
                if (this.selectedProperty && property.id === resx.id) {
                  return this.selectedProperty;
                }
                return property;
              });
            });
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getSensitives() {
    this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}/sensitive-areas`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) =>
                  (this.selectedProperty!.property_sensitives =
                    resx as PropertySensitives[])
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getNeighbours() {
    this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}/neighbours`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) =>
                  (this.selectedProperty!.property_neighbours =
                    resx as PropertyNeighbours[])
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getPersonnel() {
    this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}/personnel`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) =>
                  (this.selectedProperty!.property_personnel =
                    resx as PropertyPersonnel[])
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getPlaces() {
    //this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}/property-places`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) =>
                  (this.selectedProperty!.propertyPlaces =
                    resx as PropertyPlaces[])
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        //this.loading = false;
      });
  }

  getBoundary() {
    this.loading = true;
    ApiGet(`property/${this.selectedPropertyId}/property-boundary`)
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            res
              .json()
              .then(
                (resx) => (this.selectedProperty!.boundary = resx as Boundary)
              );
          });
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getProductionAreas(propertyId: number) {
    // In dashboard, everything needs to be fetched again, and the whole dashboard is rerendered
    // In property details, it relies on the selected property and thus the SELECTED properties production areas need to be updated
    // But cannot update selected properties production areas when there isn't one selected. (a.k.a when in a dashboard)
    // So this function needs to distinguish whether user is in dashboard or not, and act accordingly. But cannot use selectedProperty == null as the check because what if user
    // Goes into property details, then back to dashboard?

    this.loading = true;
    ApiGet(GET_EVERYTHING_PATH)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((resx) =>
            runInAction(() => {
              this.properties = resx as UserProperty[];
            })
          );
        } else if (res.status === 401) {
          // Force logout
          this.userStore.logout(true);
        }
      })
      .finally(() =>
        runInAction(() => {
          this.setSelectedProperty(propertyId);
          this.loading = false;
        })
      );
  }

  // Updates a production areas associated with user and adds block hazard and block spray info.
  // Used to fetch block hazards and block sprays on demand and append onto the properties data.
  async getProductionAreaDetails(propertyId: number | undefined) {
    this.loading = true;
    this.loadingProductionAreas = true;
    if (propertyId) {
      const res = await ApiGet(
        `property/${propertyId}/production-areas?with[]=blockHazards&with[]=blockSprays.sprayMethods`
      );
      if (res.ok) {
        let existing = this.properties?.filter((property) => {
          return property.id === Number(propertyId);
        });
        const resx = await res.json();
        runInAction(() => {
          if (existing && existing.length > 0) {
            existing[0].property_blocks = resx as PropertyBlock[];
            this.selectedProperty = existing[0];
          }
        });
      } else if (res.status === 401) {
        // Force logout
        this.userStore.logout(true);
      }
    }
    runInAction(() => {
      this.loading = false;
      this.loadingProductionAreas = false;
    });
  }

  async getProductHSNOs(
    productIds: number[],
    products?: (BasicSprayProduct | null | undefined)[] | undefined
  ) {
    this.loading = true;

    const productsToUse = products ? products : this.products;
    const productsFiltered = productsToUse?.filter((product) => {
      if (product) {
        return productIds.includes(product.id);
      }
      return false;
    });
    // Create a list of product to hsno ids list.
    const promise = new Promise<HSNOIds[]>(async (resolve, reject) => {
      const hsnoIds: HSNOIds[] = [];
      if (productsFiltered && productsFiltered.length > 0) {
        const res = await ApiGet(`hsno/product/${productIds.toString()}`);
        if (res.ok) {
          const resx = await res.json();
          const data = resx.data as Array<any>;
          for (let i = 0; i < data.length; i++) {
            const product = data[i] as Product;
            const prod: Product = {
              id: product.id,
              name: product.name,
              licence_no: product.licence_no,
              notes: product.notes,
              hsno_approval: product.hsno_approval,
              hsno_tracking: product.hsno_tracking,
              hsnos: product.hsnos,
              licensee: product.licensee
                ? {
                    id: product.licensee?.id,
                    name: product.licensee?.name
                  }
                : null,
              actives: product.actives,
              use_types: product.use_types
            };
            const pivots: HSNOPivot[] = product.hsnos;
            const ids: number[] = [];
            pivots.forEach((d: HSNOPivot) => ids.push(d.pivot.hsno_id));
            hsnoIds.push({ product: prod, hsnoIds: ids });
            if (i === productsFiltered.length - 1) {
              resolve(hsnoIds);
            }
          }
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
    // Create a list of product to HSNOs.
    try {
      const hsnoIds = await promise;
      const newProductHSNOs: ProductHSNOs[] = hsnoIds.map((ids) => {
        return { product: ids.product, hsnos: [] };
      });
      const res = await ApiGet(`hsnos/6,9`);
      if (res.ok) {
        const resx = (await res.json()) as Array<any>;
        const hsnos = [...resx[0].data, ...resx[1].data] as HSNO[];
        for (let i = 0; i < hsnoIds.length; i++) {
          const hIds = hsnoIds[i].hsnoIds;
          const currentHSNOs = hsnos.filter((hsno: HSNO) => {
            return hIds.includes(hsno.id);
          });
          newProductHSNOs[i].hsnos.push(...currentHSNOs);
        }
      }
      runInAction(() => {
        this.productHSNOs = newProductHSNOs;
      });
    } catch (error: any) {
      this.productHSNOs = [];
    } finally {
      this.loading = false;
    }
  }

  /**
   * Fetches all the data required for generating the hazard report.
   * The hazard report page is opened in a new tab. This means
   * all the data required for the hazard report page needs to
   * be fetched.
   *
   * @param propertyId
   * @param productionId
   */
  async fetchHazardReportData(propertyId: number, productionId: number) {
    this.loadingHazardReport = true;

    await this.getProducts();
    await this.requestProperties();
    await this.getProductionAreaDetails(propertyId);

    const productionArea = this.properties
      ?.filter((property) => property.id === Number(propertyId))[0]
      ?.property_blocks!.filter((area) => area.id === Number(productionId))[0];

    const productIds: number[] = [];
    productionArea?.block_sprays.forEach((areas) => {
      const id = this.products?.filter(
        (product) => product.licence_no === areas.licence_no
      )[0]?.id;
      if (id) productIds.push(id);
    });

    await this.getProductHSNOs(productIds);

    this.loadingHazardReport = false;
  }

  async fetchHsnoData(
    products: (BasicSprayProduct | null | undefined)[] | undefined
  ) {
    this.loadingHsnoData = true;

    const productIds =
      (products
        ?.map((prod) => prod?.id)
        .filter((prod) => prod !== undefined) as number[]) || [];
    await this.getProductHSNOs(productIds, products);

    this.loadingHsnoData = false;
  }

  async getProducts() {
    this.loading = true;
    if (!this.products) {
      const res = await ApiGet(`spray-products`);
      if (res.ok) {
        const resx = await res.json();
        runInAction(() => {
          this.products = resx as BasicSprayProduct[];
        });
      } else if (res.status === 401) {
        this.userStore.logout(true);
      }
    }
    runInAction(() => {
      this.loading = false;
    });
  }
}
