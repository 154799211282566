import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { UserContext } from '../../stores/StoreContexts';
import UserStore, { RegisterDetails } from '../../stores/UserStore';
import FormField from '../helpers/forms/FormField';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import './register.scss';

const Register = (): JSX.Element => {
  const userStore = useContext<UserStore>(UserContext);
  const initialValues: RegisterDetails = {
    name: '',
    company: '',
    email: '',
    password: '',
    password_confirmation: ''
  };

  return userStore.loggedIn ? (
    <Redirect to='/dashboard' />
  ) : (
    <Container className='mt-5'>
      <Row>
        <Col className='text-center'>
          <h1 className='pb-3'>Register Now</h1>
          <p>Register for free to access SprayPlan Manager.</p>
        </Col>
      </Row>
      <Row className='mt-3' style={{ justifyContent: 'center' }}>
        <Col className='mb-5' md={6}>
          <Card>
            <Card.Body>
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  userStore.register(values);
                }}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <FormField
                      fieldAs='input'
                      label='Name'
                      fieldName='name'
                      placeholder='Your Name'
                    />
                    <FormField
                      fieldAs='input'
                      label='Company'
                      fieldName='company'
                      placeholder='Your Company'
                    />
                    <FormField
                      fieldAs='input'
                      label='Email'
                      fieldName='email'
                      placeholder='Your Email'
                    />
                    <FormField
                      fieldAs='input'
                      type='password'
                      label='Password'
                      fieldName='password'
                      placeholder='Your Password'
                    />
                    <FormField
                      fieldAs='input'
                      type='password'
                      label='Confirm Password'
                      fieldName='password_confirmation'
                      placeholder='Confirm your password'
                    />
                    <Row style={{ justifyContent: 'center' }}>
                      <Button className='mt-2' variant='primary' type='submit'>
                        Register
                      </Button>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
            {userStore.loading ? <LoadingOverlay /> : <></>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(Register);
