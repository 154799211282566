import React from 'react';
import { Action, ActionKind } from '../utils/riskAssessmentReducer';

interface Props {
  sprayTime: string;
  dispatch: React.Dispatch<Action>;
}

const SprayTimeInput = ({ sprayTime, dispatch }: Props): JSX.Element => {
  return (
    <input
      id='sprayTime'
      className='form-control'
      name='sprayTime'
      type='time'
      value={sprayTime}
      onChange={(e) =>
        dispatch({
          type: ActionKind.SET_SPRAY_TIME,
          payload: e.target.value
        })
      }
    />
  );
};

export default React.memo(SprayTimeInput);
