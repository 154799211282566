import { useCallback, useContext, useEffect } from 'react';
import PropertyStore from '../stores/PropertyStore';
import { UserPropertyContext } from '../stores/StoreContexts';

export const useData = (propertyId?: number) => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);

  const hasProperties = useCallback(
    () => userPropertyStore.properties !== undefined,
    [userPropertyStore.properties]
  );

  const fetchData = useCallback(async () => {
    // Fetch properties and select the provided property.
    if (!hasProperties()) {
      await userPropertyStore.requestProperties();
      if (propertyId) userPropertyStore.setSelectedProperty(propertyId);
    }
    // Fetch production area details from the provided property.
    if (!propertyId) return;
    await userPropertyStore.getProductionAreaDetails(propertyId);
  }, [propertyId, userPropertyStore, hasProperties]);

  useEffect(() => {
    fetchData();
    if (!userPropertyStore.productions) userPropertyStore.getProductions();
    if (!userPropertyStore.regions) userPropertyStore.getRegions();
    if (!userPropertyStore.methods) userPropertyStore.getMethods();
  }, [userPropertyStore, fetchData]);
};
