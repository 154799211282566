import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Region } from '../../models/propertyTypes';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from '../modals/ModalFormField';
import ModalSelectFormField from '../modals/ModalSelectFormField';
import { addPropertySchema } from '../validation_schemas/FormSchemas';

export interface Property {
  propertyID?: number;
  propertyName?: string;
  regionID?: number;
  address?: string;
}

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddProperty = ({ showModal, setShowModal }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const history = useHistory();

  const initialValues: Property = {};

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add a new property
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={addPropertySchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            ApiPost('property', data)
              .then((res) => {
                if (!res.ok) {
                  errorToast('Something went wrong');
                } else {
                  res.json().then((data: any) => {
                    userPropertyStore.setSelectedProperty(data.id);
                  });
                }
              })
              .finally(async () => {
                setSubmitting(false);
                setShowModal(false);
                await userPropertyStore.requestProperties();
                history.push(
                  `/property-details/${userPropertyStore.selectedPropertyId}`
                );
              });
          }}
        >
          {(props) => (
            <Form className='inner' id='register-form'>
              <Modal.Body>
                {/* Here we can have the body content */}
                <p style={{ fontWeight: 'bold', fontSize: 24 }}>
                  Property details
                </p>

                <div>
                  <ModalFormField
                    label='ID / Number'
                    subLabel='A unique number like an Rpin or Kpin'
                    fieldName='property_id'
                    fieldAs='input'
                    placeholder='Property ID or Number (Freeform)'
                    errorName='property_id'
                    required
                  />
                  <ModalFormField
                    label='Name'
                    subLabel='The name of the property or business'
                    fieldName='property_name'
                    fieldAs='input'
                    placeholder='Property Name'
                    errorName='property_name'
                    required
                  />
                  <ModalSelectFormField
                    label='Region'
                    subLabel='The region the property resides in'
                    fieldName='region_id'
                    fieldAs='select'
                    errorName='production_id'
                    required
                  >
                    <>
                      <option value=''>-- Select a region --</option>
                      {userPropertyStore.regions?.map((region: Region) => (
                        <option key={`region_${region.name}`} value={region.id}>
                          {region.name}
                        </option>
                      ))}
                    </>
                  </ModalSelectFormField>
                  <ModalFormField
                    label='Address'
                    subLabel='The physical address of the property'
                    fieldName='address'
                    fieldAs='input'
                    placeholder='Address'
                    errorName='address'
                    required
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create Property
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddProperty);
