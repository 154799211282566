import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapGL, {
  FullscreenControl,
  GeolocateControl,
  MapContext,
  NavigationControl,
  ScaleControl
} from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import { isEqual } from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useHistory } from 'react-router-dom';
import { Feature, FeatureCollection, ViewPort } from '../../../models/types';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { useMultiBoundary } from '../../../utils/useMultiBoundary';
import ConfirmationModal from '../../helpers/modals/ConfirmationModal';
import AddPropertyPlace from '../../modals/AddPropertyPlace';
import EditPropertyPlace from '../../modals/EditPropertyPlace';
import Geocode from './Geocode';
import MapFitBounds from './MapFitBounds';
import MapLabels from './MapLabels';
import MapStyleButton from './MapStyleButton';
import './PropertyMap.scss';
import {
  deleteProperty,
  fitToBounds,
  hasProperty,
  useMapRequests
} from './PropertyMapUtils';

interface Props {
  enableEdit?: boolean;
  polygonControl?: boolean;
  pointControl?: boolean;
  trashControl?: boolean;
  compass?: boolean;
  geoLocate?: boolean;
  geocoder?: boolean;
  scale?: boolean;
  initialStyle: string;
  consumerFunction?: (map: any) => void;
}

const PropertyMap = ({
  enableEdit = false,
  polygonControl = false,
  pointControl = false,
  trashControl = false,
  compass = false,
  geoLocate = false,
  geocoder = false,
  scale = false,
  initialStyle,
  consumerFunction
}: Props): JSX.Element => {
  const history = useHistory();
  const mapRef = useRef<any>(null);
  const [create, update, _delete] = useMapRequests();
  const userPropertyStore = useContext(UserPropertyContext);
  const objectBoundaries = useMultiBoundary();
  const objectPropertyPlaces = userPropertyStore.selectedProperty
    ?.propertyPlaces as unknown as Feature[];
  const [popupLatLng, setPopupLatLng] = useState<[number, number] | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addPropertyPlace, setAddPropertyPlace] = useState(false);
  const [propertyPlaceToAdd, setPropertyPlaceToAdd] = useState<Feature | null>(
    null
  );
  const [propertyPlaceToEdit, setPropertyPlaceToEdit] =
    useState<Feature | null>(null);
  const [editPropertyPlace, setEditPropertyPlace] = useState(false);
  const [mapStyle, setMapStyle] = useState(initialStyle);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<number>(-1);
  const [viewport, setViewport] = useState<ViewPort>({
    latitude: -41.0828166,
    longitude: -186.4514397,
    zoom: 4
  });
  const [collection, setCollection] = useState<FeatureCollection>(
    userPropertyStore.selectedProperty
      ? objectBoundaries?.length > 0
        ? {
            type: 'FeatureCollection',
            features: [...objectBoundaries, ...objectPropertyPlaces]
          }
        : {
            type: 'FeatureCollection',
            features: [...objectPropertyPlaces]
          }
      : {
          type: 'FeatureCollection',
          features: []
        }
  );

  useEffect(() => {
    setCollection(
      userPropertyStore.selectedProperty
        ? objectBoundaries?.length > 0
          ? {
              type: 'FeatureCollection',
              features: [...objectBoundaries, ...objectPropertyPlaces]
            }
          : {
              type: 'FeatureCollection',
              features: [...objectPropertyPlaces]
            }
        : {
            type: 'FeatureCollection',
            features: []
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userPropertyStore,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(objectBoundaries),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(objectPropertyPlaces)
  ]);

  useEffect(() => {
    // Have property places but no boundary then return.
    if (objectPropertyPlaces?.length > 0 && !objectBoundaries) return;
    if (objectBoundaries?.length > 0 || objectPropertyPlaces?.length > 0) {
      fitToBounds(mapRef.current.getMap(), false, 75, [
        ...objectBoundaries,
        ...objectPropertyPlaces
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = ({ features }: FeatureCollection) => {
    const newFeatures = [...collection.features, ...features];
    if (features[0].geometry.type === 'Point') {
      setAddPropertyPlace(true);
      setPropertyPlaceToAdd(features[0]);
    } else {
      create({ ...collection, features: newFeatures });
      setCollection({ ...collection, features: newFeatures });
    }
  };

  const onUpdate = ({ features }: FeatureCollection) => {
    update({ ...collection }, features);
  };

  const onDelete = ({ features }: FeatureCollection) => {
    _delete({ ...collection }, features);
  };

  const onSelect = ({ features }: FeatureCollection) => {
    const index = collection.features.findIndex((feature) =>
      features.find((_feature: Feature) => isEqual(_feature, feature))
    );
    // Set location for a marker label.
    if (index > -1 && collection.features[index].geometry.type !== 'Polygon') {
      setPopupLatLng([
        collection.features[index].geometry.coordinates[1] as number,
        collection.features[index].geometry.coordinates[0] as number
      ]);
    }
    setSelectedFeatureIndex(index);
  };

  return (
    <>
      <Card
        style={{
          borderRadius: 7,
          overflow: 'hidden',
          pointerEvents: enableEdit ? 'all' : 'none'
        }}
      >
        <MapGL
          {...viewport}
          ref={mapRef}
          dragPan={enableEdit}
          drageRotate={enableEdit}
          scrollZoom={enableEdit}
          accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          style={{ width: '100%', minHeight: '60vh', height: '100%' }}
          onViewportChange={(nextViewport: ViewPort) =>
            setViewport(nextViewport)
          }
          attributionControl={false}
          mapStyle={`mapbox://styles/hortplus/${mapStyle}`}
          preserveDrawingBuffer={true}
        >
          <Draw
            style={{ width: '100%', height: '100%' }}
            touchEnabled={enableEdit}
            polygonControl={polygonControl}
            pointControl={pointControl}
            trashControl={trashControl}
            lineStringControl={false}
            combineFeaturesControl={false}
            uncombineFeaturesControl={false}
            data={collection}
            onDrawCreate={onCreate}
            onDrawUpdate={onUpdate}
            onDrawDelete={onDelete}
            onDrawSelectionChange={onSelect}
            onChange={() => setSelectedFeatureIndex(-1)}
          />
          {geoLocate && (
            <GeolocateControl position='bottom-left' trackUserLocation />
          )}

          {geocoder && (
            <Geocode
              mapRef={mapRef}
              accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              position='top-left'
              limit={3}
              countries={'NZ'}
            />
          )}
          {compass && (
            <NavigationControl
              position='top-left'
              showZoon={false}
              showCompass
              visualizePitch
            />
          )}
          {scale && <ScaleControl position='bottom-right' unit='metric' />}
          {enableEdit && <FullscreenControl position='top-right' />}
          {enableEdit && (
            <MapStyleButton
              setMapStyle={setMapStyle}
              mapStyle={mapStyle}
              viewport={viewport}
            />
          )}
          {enableEdit && (
            <MapFitBounds
              mapRef={mapRef}
              objectBoundaries={objectBoundaries}
              padding={75}
            />
          )}
          {consumerFunction && (
            <MapContext.Consumer>
              {(map: any) => {
                consumerFunction(map);
              }}
            </MapContext.Consumer>
          )}
          <MapLabels
            popupLatLng={popupLatLng}
            selectedFeatureIndex={selectedFeatureIndex}
            setSelectedFeatureIndex={setSelectedFeatureIndex}
            collection={collection}
            setEditPropertyPlace={setEditPropertyPlace}
            setPropertyPlaceToEdit={setPropertyPlaceToEdit}
            enableEdit={enableEdit}
          />
        </MapGL>
      </Card>
      {/**
       * Delete map button and confirmation modal.
       */}
      {enableEdit && (
        <div className='d-flex justify-content-center my-2'>
          <div className='mt-4  mr-4'>
            <Button variant='success' onClick={() => history.goBack()}>
              Done
            </Button>
          </div>
          <div className='mt-4'>
            <Button
              variant='outline-danger'
              disabled={!hasProperty(userPropertyStore)}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete map
            </Button>
          </div>
        </div>
      )}
      <ConfirmationModal
        title='Confirm delete?'
        body={
          <p>
            Do you wish to delete the property map from the
            <b> {userPropertyStore.selectedProperty?.property_name}. </b>
            This will delete the boundary and all property places.
          </p>
        }
        confirmBtnText='Delete'
        confirmBtnVariant='danger'
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        confirmAction={() => deleteProperty(userPropertyStore)}
      />
      {/**
       * A Modal form for creating new property places.
       */}
      <AddPropertyPlace
        showModal={addPropertyPlace}
        setShowModal={setAddPropertyPlace}
        propertyPlaceToAdd={propertyPlaceToAdd}
      />

      <EditPropertyPlace
        showModal={editPropertyPlace}
        setShowModal={setEditPropertyPlace}
        propertyPlaceToEdit={propertyPlaceToEdit}
      />
    </>
  );
};

export default observer(PropertyMap);
