import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useState } from 'react';
import Select from 'react-select-virtualized';
import { Option } from '../../../models/types';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import '../../production_areas/production_cards/production_cards.scss';

interface Props {
  onChange: (v: Option) => void;
  inputValue?: string;
}

// TODO: Need to figure out the react-select types.
const ProductSelect = ({ onChange, inputValue }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  const createSelectOptions = useCallback(() => {
    const optns =
      userPropertyStore.products?.map((product) => ({
        label: product.name,
        value: product.licence_no
      })) || [];
    return optns;
  }, [userPropertyStore.products]);

  const options = createSelectOptions();
  const [value, setValue] = useState<Option | undefined>(
    options.find((op) => op.value === inputValue)
  );

  return (
    <Select
      options={options ? options : []}
      placeholder='Select a product'
      value={value}
      onChange={(v: any) => {
        onChange(v);
        setValue(v);
      }}
      filterOption={(option: any, inputValue: string) => {
        const inputLC = inputValue.toLocaleLowerCase();
        return option.label.toLowerCase().includes(inputLC);
      }}
    />
  );
};

export default observer(ProductSelect);
