import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { ApiPost } from '../../utils/utils';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ModalFormField from './ModalFormField';

interface NewCertificate {
  name: string;
  number: string;
  expire: string;
}

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  personId: number;
}

const AddCertificate = ({
  showModal,
  setShowModal,
  personId
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialValues: NewCertificate = { name: '', number: '', expire: '' };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Add a certificate
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            ApiPost(
              'property/' +
                userPropertyStore.selectedPropertyId +
                '/personnel/' +
                personId +
                '/certification',
              values
            )
              .then((res: Response) => {
                if (res.ok) userPropertyStore.getPersonnel();
                else {
                  errorToast('Something went wrong');
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
                setShowModal(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Modal.Body>
                <p></p>
                <ModalFormField
                  label='Certificate'
                  subLabel=''
                  fieldName='name'
                  fieldAs='input'
                  placeholder=''
                  errorName='name'
                />
                <ModalFormField
                  label='Number/Code'
                  subLabel=''
                  fieldName='number'
                  fieldAs='input'
                  placeholder=''
                  errorName='number'
                />
                <ModalFormField
                  label='Expiry Date'
                  subLabel=''
                  fieldName='expire'
                  fieldType='date'
                  placeholder=''
                  errorName='expire'
                  required
                />
              </Modal.Body>
              <Modal.Footer>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      onClick={() => setShowModal(false)}
                      variant='outline-secondary'
                      disabled={props.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='success'
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      Create New Certificate
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default observer(AddCertificate);
