import { makeAutoObservable, runInAction } from 'mobx';
import 'react-toastify/dist/ReactToastify.css';
import {
  errorToast,
  successToast
} from '../components/helpers/toasts/ToastUtils';
import { User } from '../models/userTypes';
import {
  ApiGet,
  ApiPost,
  InitXSRFToken,
  clearUsersLocalStorage
} from '../utils/utils';

export interface loginDetails {
  email?: String;
  password?: String;
}

export interface RegisterDetails {
  name: string;
  company: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export default class UserStore {
  loggedIn: boolean = false;
  user?: User;
  loading: boolean = false;

  //Simple login and logout functions to port over.

  constructor() {
    makeAutoObservable(this);

    // Constructor to fetch current user details from localstorage
    // Attempt to get user Id
    let local = localStorage.getItem('userId');
    let localUser: User;

    // Perform get request to match ID
    if (local) {
      // Get the current logged in user from backend
      this.loading = true;
      ApiGet('user', true)
        .then((res: unknown) => {
          localUser = res as User;
          runInAction(() => {
            this.user = localUser;
            this.loggedIn = true;
          });
        })
        .catch(() => {})
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    }
  }

  login(details: loginDetails) {
    InitXSRFToken();
    this.loading = true;
    if (details) {
      ApiPost('user/login', details)
        .then((res: Response) => {
          if (res.ok) {
            ApiGet('user', true).then((res: unknown) => {
              runInAction(() => {
                successToast('Succesfully logged in!');
                this.user = res as User;
                this.loggedIn = true;
                localStorage.setItem('userId', JSON.stringify(this.user.id));
                clearUsersLocalStorage();
              });
            });
          } else {
            errorToast('Login Failed!');
          }
        })
        .finally(() =>
          runInAction(() => {
            this.loading = false;
          })
        );
    }
  }

  register(details: RegisterDetails) {
    this.loading = true;
    ApiPost('user/register', details)
      .then(async (res: Response) => {
        if (res.ok) {
          successToast('Account created');
          this.login({ email: details.email, password: details.password });
        } else {
          const body = await res.json();
          if (body.errors.email[0]) errorToast(body.errors.email[0]);
          else errorToast('Registration Failed!');
        }
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  logout(force?: boolean) {
    ApiPost('user/logout', {})
      .then((res: Response) => {
        if (res.ok) {
          runInAction(() => {
            this.loggedIn = false;
            this.user = undefined;
          });
        } else {
          // What to do if the logout fails?
        }
      })
      .finally(() => {
        localStorage.removeItem('userId');
      });

    if (force) {
      runInAction(() => {
        this.loggedIn = false;
        this.user = undefined;
        localStorage.removeItem('userId');
      });
    }
  }

  isLoggedIn(): boolean {
    return this.user ? true : false;
  }
}
