import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { UserContext } from '../../stores/StoreContexts';
import { ReactNode, useContext } from 'react';
import Navbar from '../navbar/Navbar';
require('./layout.scss');

type Props = {
  children: ReactNode;
  hideNav?: boolean;
};

const Layout = ({ children, hideNav }: Props) => {
  const userStore = useContext(UserContext);

  return (
    <>
      <Header />
      {userStore.isLoggedIn() ? <Navbar /> : null}
      <Container id='page-content' fluid className='px-0'>
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default observer(Layout);
