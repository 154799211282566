import { Col, Row } from 'react-bootstrap';
import { Delcaration } from '../../risk-assessment/utils/riskAssessmentReducer';

interface Props {
  declaration: Delcaration | null | undefined;
}

const ReportDeclaration = ({ declaration }: Props): JSX.Element => {
  return (
    <div>
      <hr />
      <h4 className='sub-heading mb-3 font-weight-bold'>Declaration</h4>
      <p className='declaration-paragraph'>
        I declare this risk assessment is correct.
      </p>
      <Row className='pl-3'>
        <Col style={{ backgroundColor: '#ebebeb' }} className='pt-3 pb-2 pl-4'>
          {!declaration ? (
            <p>No declaration was found</p>
          ) : (
            <>
              <h5 className='declaration-paragraph font-weight-bold'>
                {declaration.name}
              </h5>
              <h6>{declaration.date}</h6>
            </>
          )}
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default ReportDeclaration;
