const Heading = ({
  children
}: {
  children: (JSX.Element | string)[] | string;
}) => {
  return (
    <h5 className='table-heading' style={{ color: 'gray' }}>
      {children}
    </h5>
  );
};

export default Heading;
