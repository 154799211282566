import '@fontsource/poppins';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// Initialize Google Analytics when in production.
if (
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  const TRACKING_ID = 'G-GLBYF4WS3D';
  ReactGA.initialize(TRACKING_ID);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
