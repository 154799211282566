import * as Yup from 'yup';

export const propertyPlaceSchema = Yup.object().shape({
  // icon: Yup.string()
  //   .max(30, 'This is too long for an icon name')
  //   .required('Please provide a icon'),
  // type: Yup.string()
  //   .max(30, 'This is too long for a type')
  //   .required('Please provide a type.'),
  name: Yup.string()
    .max(40, 'Name is too long. (40 Characters max.)')
    .required('Please provide a name.')
  // description: Yup.string()
  //   .max(150, 'Description is too long. (150 Characters max.)')
  //   .required('Please provide a description.'),
});

export const productionAreaSchema = Yup.object().shape({
  production_id: Yup.number().required('Please select an option.'),
  name: Yup.string()
    .max(50, 'Name is too long. (50 Characters max.)')
    .required('Please complete this field.'),
  area: Yup.number()
    .nullable(true)
    .max(9999999999, 'Max area of 10 digits.')
    .typeError('Please enter numbers only.'),
  review_date: Yup.date().required('Please select a date.')
});

export const addPropertySchema = Yup.object().shape({
  property_id: Yup.string()
    .max(30, 'ID is too long (30 characters max.)')
    .required('Please complete this field.'),
  property_name: Yup.string()
    .max(50, 'Name is too long. (50 Characters max.)')
    .required('Please complete this field.'),
  region_id: Yup.number().required('Please select an option.'),
  address: Yup.string()
    .max(150, 'Address is too long. (150 Characters max.)')
    .required('Please complete this field.')
});

export const addSprayPlanSchema = Yup.object().shape({
  licence_no: Yup.string().required('Please select an option.'),
  applications: Yup.string().max(
    255,
    'Applications too long. (255 Characters max.)'
  ),
  target: Yup.string().max(50, 'Target too long. (50 Characters max.)'),
  timing: Yup.string().max(50, 'Timing too long. (50 Characters max.)')
});

export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required('Your current password is required.'),
  new_password: Yup.string().required('Your new password is required,'),
  new_password_confirmation: Yup.string().required(
    'You must confirm your new password.'
  )
});
