import { observer } from 'mobx-react-lite';
import { Table } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';

interface Props {
  productionArea: PropertyBlock;
}

const Hazards = ({ productionArea }: Props): JSX.Element => {
  return (
    <>
      {productionArea.block_hazards &&
      productionArea.block_hazards.length === 0 ? (
        <b className='empty-section'>No Hazards</b>
      ) : (
        <Table striped hover size='sm' responsive>
          <thead>
            <tr>
              <th>Description</th>
              <th>Management</th>
            </tr>
          </thead>
          <tbody>
            {(productionArea?.block_hazards &&
              productionArea?.block_hazards.map((areas, i) => (
                <tr key={i}>
                  <td>
                    {areas.description || (
                      <i style={{ color: 'gray' }}>(blank description)</i>
                    )}
                  </td>
                  <td>
                    {areas.management || (
                      <i style={{ color: 'gray' }}>(blank management)</i>
                    )}
                  </td>
                </tr>
              ))) || <tr></tr>}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default observer(Hazards);
