import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Feature } from '../../../models/types';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { useMultiBoundary } from '../../../utils/useMultiBoundary';
import PropertyImage from '../map/PropertyImage';
import PropertyMap from '../map/PropertyMap';
import { fitToBounds, satelliteStyle } from '../map/PropertyMapUtils';
import './property_cards.scss';

const MapCard = (): JSX.Element => {
  const [hasMap, setHasMap] = useState(false);
  const userPropertyStore = useContext(UserPropertyContext);
  const objectBoundaries = useMultiBoundary();
  const objectPropertyPlaces = userPropertyStore.selectedProperty
    ?.propertyPlaces as unknown as Feature[];
  const mapType = userPropertyStore.selectedProperty?.map_type;
  const property = userPropertyStore.selectedProperty;

  useEffect(() => {
    const checkForMap = () => {
      if (property) {
        const image = property.property_image;
        const boundary = property.boundary;
        // Check if a map exists
        if (boundary?.type) {
          setHasMap(true);
        } else if (image && image[0]) {
          setHasMap(true);
        } else {
          setHasMap(false);
        }
      }
    };
    checkForMap();
  }, [property]);

  return (
    <Card border='muted'>
      <Card.Header>
        <Row>
          <Col className='d-flex justify-content-between align-items-center'>
            <h5 className='m-0'>2. Property Map</h5>
            {hasMap && mapType === 'GIS' && (
              <Button
                variant='link'
                as={Link}
                type='button'
                to={`/property-details/${property?.id}/map`}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <>
          {userPropertyStore.loading ? (
            <p>Loading...</p>
          ) : (
            <Container>
              {hasMap && mapType === 'GIS' ? ( // Display map
                <PropertyMap
                  enableEdit={false}
                  initialStyle={satelliteStyle}
                  consumerFunction={(map) => {
                    fitToBounds(map, false, 20, [
                      ...objectBoundaries,
                      ...objectPropertyPlaces
                    ]);
                  }}
                />
              ) : hasMap && mapType === 'OWN' ? ( // Otherwise, display map image
                <PropertyImage setHasMap={setHasMap} deletable />
              ) : (
                // Otherwise, display empty card
                <>
                  <p className='empty-card-text'>No property map added</p>
                  <div className='d-flex justify-content-center my-3'>
                    <div className='mr-2'>
                      <Button
                        variant='success'
                        as={Link}
                        to={`/property-details/${property?.id}/map`}
                      >
                        Create a new map
                      </Button>
                    </div>
                    <div className='ml-2'>
                      <Button
                        variant='outline-success'
                        as={Link}
                        to={`/property-details/${property?.id}/image`}
                      >
                        Upload an image
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Container>
          )}
        </>
      </Card.Body>
    </Card>
  );
};

export default MapCard;
