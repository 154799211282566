import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../stores/StoreContexts';
import './header.scss';
import Sponsors from '../landing/Sponsors/Sponsors';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const userStore = useContext(UserContext);

  return (
    <>
      <div className='sp-nav-wrapper'>
        <Container>
          <Navbar
            collapseOnSelect
            expand='lg'
            className='sp-nav'
            variant='dark'
            expanded={expanded}
          >
            <Navbar.Brand
              as={Link}
              to={userStore.isLoggedIn() ? '/dashboard' : '/'}
              className='sp-nav-inner'
            >
              <Image
                src={process.env.REACT_APP_S3_ASSETS + 'sprayplan-logo.svg'}
                alt='SprayPlan Manager Logo'
              ></Image>
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls='responsive-navbar-nav'
              onClick={() =>
                setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))
              }
            />

            {userStore.isLoggedIn() ? (
              <></>
            ) : (
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className='justify-content-end'
              >
                <Nav className='align-items-center'>
                  <Row className='ml-1 py-2'>
                    <Col xs={1} sm={1} md={1}>
                      <FontAwesomeIcon icon={faEnvelope} color='white' />
                    </Col>
                    <Col className='pl-2'>
                      <a
                        className='text-decoration-none text-white'
                        onClick={() => setExpanded(false)}
                        href='mailto:support@hortplus.com'
                      >
                        Get in Touch
                      </a>
                    </Col>
                  </Row>

                  <Nav.Link
                    onClick={() => setExpanded(false)}
                    as={Link}
                    to='/register'
                  >
                    <Button variant='outline-light'>Register Now</Button>
                  </Nav.Link>
                  <Nav.Link as={Link} to='/login'>
                    <Button variant='primary'>Login</Button>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            )}
            {userStore.isLoggedIn() && <Sponsors mode='header' />}
          </Navbar>
        </Container>
      </div>
    </>
  );
};

export default Header;
