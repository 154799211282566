import { HSNO, ProductHSNOs } from '../../../models/propertyTypes';

export interface Row {
  product: string;
  approval: string;
  tracking: string;
  [key: string]: string;
}

type HTKey =
  | '61-dermal'
  | '61-inhalation'
  | '61-oral'
  | '61-aspiration'
  | '63-skinirritant'
  | '64-eyeirritant'
  | '65-sesitisation'
  | '66-mutagen'
  | '67-carcinogen'
  | '68-reproductivedevelopment'
  | '69-targetorgan';

type ETKey =
  | '91-aquatic'
  | '92-soil'
  | '93-terrestrialvertebrates'
  | '94-terrestrialinvertebrates';

const htClassToDegrees = {
  '61-dermal': ['A', 'B', 'C', 'D', 'E'],
  '61-inhalation': ['A', 'B', 'C', 'D', 'E'],
  '61-oral': ['A', 'B', 'C', 'D', 'E'],
  '61-aspiration': ['A'],
  '63-skinirritant': ['A', 'B'],
  '64-eyeirritant': ['A'],
  '65-sesitisation': ['A', 'B'],
  '66-mutagen': ['A', 'B'],
  '67-carcinogen': ['A', 'B'],
  '68-reproductivedevelopment': ['A', 'B', 'C'],
  '69-targetorgan': ['A', 'B']
};

const etClassToDegrees = {
  '91-aquatic': ['A', 'B', 'C', 'D'],
  '92-soil': ['A', 'B', 'C', 'D'],
  '93-terrestrialvertebrates': ['A', 'B', 'C'],
  '94-terrestrialinvertebrates': ['A', 'B', 'C']
};

/**
 * Takes a hsno and creates a row based on the hsno's
 * data. If the hsno degree is 'A', then create a row
 * with each of that hsno's degrees.
 *
 * For example, if the hsno is class 6.1 and type dermal
 * with degree 'A', then this row will populate the dermal
 * column and columns 'A' through 'E'.
 */
const createRow = (hsno: HSNO, row: Row, code: number) => {
  const major = hsno.code.major;
  const minor = hsno.code.minor;
  const type = hsno.type;
  const name = hsno.code.name;
  const degree = hsno.degree;
  const key = `${major}${minor}x${type ? type : name}x${degree}`;
  const formattedKey = key.replace('/', '');
  if (hsno.degree === 'A') {
    let n: string = type ? type : name;
    n = n
      .toLocaleLowerCase()
      .replace(/\s+/g, '')
      .replace(/[^a-zA-Z ]/g, '');
    const degrees =
      code === 6
        ? htClassToDegrees[`${major}${minor}-${n}` as HTKey]
        : etClassToDegrees[`${major}${minor}-${n}` as ETKey];
    if (degrees) {
      degrees.forEach((d) => {
        const key = `${major}${minor}x${type ? type : name}x${d}`;
        const formattedKey = key.replace('/', '');
        row[formattedKey] = 'X';
      });
    }
  } else {
    row[formattedKey] = 'X';
  }
};

export const makeData = (
  productHSNOs: ProductHSNOs[] | undefined,
  code: number
) => {
  const data: Row[] = [];
  const allHSNOs: ProductHSNOs[] | undefined = productHSNOs?.map((list) => ({
    product: list.product,
    hsnos: list.hsnos.filter((hsno) => hsno.code.major === code)
  }));
  if (allHSNOs) {
    allHSNOs.forEach((list) => {
      const row: Row = {
        product: list.product.name,
        approval: '',
        tracking: ''
      };
      const hsnos = list.hsnos;
      if (hsnos.length !== 0) {
        hsnos.forEach((hsno) => {
          const tracking = hsno.requires_tracking;
          const approval = hsno.requires_approved_handler;
          if (tracking) row.approval = 'X';
          if (approval) row.tracking = 'X';
          createRow(hsno, row, code);
        });
        data.push(row);
      }
    });
  }
  return data;
};
