import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Alert, Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import PropertyMap from './map/PropertyMap';
import { satelliteStyle } from './map/PropertyMapUtils';

const PropertyMapExplorer = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const history = useHistory();
  let { propertyId } = useParams<any>();
  useData(propertyId);

  useEffect(() => {
    userPropertyStore.setSelectedProperty(Number(propertyId));
  }, [userPropertyStore, propertyId]);

  return (
    <>
      {userPropertyStore.loading ? (
        <LoadingOverlay />
      ) : (
        <Container fluid className='px-5 py-3'>
          <div>
            <Row>
              <Col>
                <Button variant='link' onClick={() => history.goBack()}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Cancel
                </Button>
              </Col>
              <Col className='text-center'>
                <h3 className='text-center'>Property Map</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <b>{userPropertyStore.selectedProperty?.property_name}</b>
              </Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <p>{userPropertyStore.selectedProperty?.address}</p>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col>
                <Alert variant='info' className='mx-auto d-inline-block'>
                  Please create <b>one</b> polygon to encompass your entire
                  property and add points to indicate areas of interest.
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col className='text-center'>
                <Image
                  src={
                    process.env.REACT_APP_S3_ASSETS + 'srayplan_map_guide.svg'
                  }
                  alt='SprayPlan map guide'
                  style={{ maxWidth: '850px', paddingBottom: '1em' }}
                />
              </Col>
            </Row>
          </div>
          <div className='text-center'>
            <PropertyMap
              enableEdit
              polygonControl
              pointControl
              trashControl
              geoLocate
              geocoder
              scale
              initialStyle={satelliteStyle}
            />
          </div>
        </Container>
      )}
    </>
  );
};

export default observer(PropertyMapExplorer);
