import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import RiskAssessmentMap from '../../risk-assessment/RiskAssessmentMap/RiskAssessmentMap';
import PropertyDetails from './PropertyDetails/PropertyDetails';
import './report-property.scss';

interface Props {
  property: UserProperty | null | undefined;
  productionArea: PropertyBlock | null | undefined;
  sprayDate: string | undefined;
  sprayTime: string | undefined;
  reportRef: React.MutableRefObject<HTMLDivElement | null>;
}

const ReportProperty = ({
  property,
  productionArea,
  sprayDate,
  sprayTime,
  reportRef
}: Props): JSX.Element => {
  const history = useHistory();
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: 'Risk Assessment Report'
  });

  return (
    <div>
      <Row>
        <Col className='hide-on-print text-center' xs={2}>
          <Button
            variant='link'
            onClick={() => history.push('/risk-assessment')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </Button>
        </Col>
        <Col
          className='text-center w-100'
          style={{ maxWidth: '100% !important' }}
        >
          <h2>Risk Assessment for {property?.property_name}</h2>
        </Col>
        <Col className='hide-on-print text-center' xs={2}>
          <Button variant='primary' onClick={handlePrint}>
            Print
          </Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col></Col>
        <Col className='text-center' md={6}>
          <h4 className='bg-warning mt-2 p-2'>
            Spraying on <b>{sprayDate}</b> from <b>{sprayTime}</b>
          </h4>
        </Col>
        <Col></Col>
      </Row>
      <Row className='mb-4 ml-6'>
        <PropertyDetails property={property} productionArea={productionArea} />
      </Row>
      <Row>
        <Col md>{property && <RiskAssessmentMap />}</Col>
      </Row>
    </div>
  );
};

export default ReportProperty;
