import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import EnvironmentToxicity from './EnvironmentToxicity';
import FrontPage from './FrontPage';
import './hazard_report.scss';
import HumanToxicity from './HumanToxicity';
import InitialInfo from './InitialInfo';

const HazardReport = (): JSX.Element => {
  const reportRef = useRef<HTMLDivElement | null>(null);
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const { propertyId, productionId }: any = useParams();

  useEffect(() => {
    userPropertyStore.fetchHazardReportData(
      Number(propertyId),
      Number(productionId)
    );
  }, [propertyId, productionId, userPropertyStore]);

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: 'Hazard Report'
  });

  return (
    <>
      {userPropertyStore.loadingHazardReport ? (
        <LoadingOverlay />
      ) : (
        <Container className='mb-4'>
          <div ref={reportRef}>
            <style type='text/css' media='print'>
              {'\
              @page { size: landscape; }\
            '}
            </style>
            <div className='px-3 py-3'>
              <Row>
                <Col className='hide-on-print'></Col>
                <Col
                  className='text-center'
                  style={{ maxWidth: '100% !important' }}
                >
                  <h3 className='main-heading'>Hazard Report</h3>
                </Col>
                <Col className='hide-on-print'>
                  <Button
                    variant='primary'
                    style={{ float: 'right' }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </Col>
              </Row>
            </div>
            <div className='mt-4'>
              <Row>
                <Col>
                  <FrontPage />
                </Col>
              </Row>
            </div>
            <div className='page-break' />
            <div>
              <Row>
                <Col>
                  <InitialInfo />
                </Col>
              </Row>
            </div>
            <div className='page-break' />
            <div>
              <Row>
                <Col>
                  <HumanToxicity />
                </Col>
              </Row>
            </div>
            <div className='page-break' />
            <div>
              <Row>
                <Col>
                  <EnvironmentToxicity />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default observer(HazardReport);
