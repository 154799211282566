import { ApmRoute } from '@elastic/apm-rum-react';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import Account from './components/account/Account';
import ChangePassword from './components/account/ChangePassword';
import Dashboard from './components/dashboard/Dashboard';
import GoogleAnalytics from './components/google_analytics/GoogleAnalytics';
import HazardReport from './components/hazard_report/HazardReport';
import { ToastContainer } from './components/helpers/toasts/ToastUtils';
import Landing from './components/landing/Landing';
import Layout from './components/layout/Layout';
import Login from './components/login/Login';
import ProductionAreas from './components/production_areas/ProductionAreas';
import Properties from './components/properties/Properties';
import PropertyMapExplorer from './components/properties/PropertyMapExplorer';
import PropertyMapUpload from './components/properties/PropertyMapUpload';
import Register from './components/register/Register';
import ForgotPassword from './components/reset_password/ForgotPassword';
import ResetPassword from './components/reset_password/ResetPassword';
import RiskAssessmentReport from './components/risk-assessment-report/RiskAssessmentReport';
import RiskAssessment from './components/risk-assessment/RiskAssessment';
import SprayPlanReport from './components/sprayplan_report/SparyPlanReport';
import Support from './components/support/Support';
import apm, { setupApmUser } from './rum';
import { UserContext } from './stores/StoreContexts';

const PageDoesNotExistAlert = () => {
  const [show, setShow] = useState(true);

  return (
    <Row>
      <Col>
        <Alert
          variant='danger'
          show={show}
          dismissible
          onClose={() => setShow(false)}
        >
          The requested page does not exist.
        </Alert>
      </Col>
    </Row>
  );
};

// This is our root component
function App() {
  const userStore = useContext(UserContext);

  useEffect(() => setupApmUser(apm, userStore.user?.id), [userStore.user?.id]);

  return (
    <>
      <ToastContainer />
      <Router>
        <GoogleAnalytics />
        <Switch>
          <ApmRoute
            path='/'
            exact
            component={() => (
              <Layout>
                <Landing />
              </Layout>
            )}
          />
          <ApmRoute
            path='/register'
            exact
            component={() => (
              <Layout>
                <Register />
              </Layout>
            )}
          />
          <ApmRoute
            path='/login'
            exact
            component={() => (
              <Layout>
                <Login />
              </Layout>
            )}
          />
          <ApmRoute
            path='/forgot-password'
            exact
            component={() => (
              <Layout>
                <ForgotPassword />
              </Layout>
            )}
          />
          <ApmRoute
            path='/reset-password/:id/:token'
            exact
            component={() => (
              <Layout>
                <ResetPassword />
              </Layout>
            )}
          />

          {/* Auth required for following routes*/}
          {userStore.loggedIn && !userStore.loading ? (
            <>
              <ApmRoute
                path='/dashboard'
                exact
                component={() => (
                  <Layout>
                    <Dashboard />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/property-details/:propertyId'
                exact
                component={() => (
                  <Layout>
                    <Properties />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/risk-assessment'
                exact
                component={() => (
                  <Layout>
                    <RiskAssessment />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/risk-assessment/report'
                exact
                component={() => (
                  <Layout>
                    <RiskAssessmentReport />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/production-area/:propertyId/:productionId'
                exact
                component={() => (
                  <Layout>
                    <ProductionAreas />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/support'
                exact
                component={() => (
                  <Layout>
                    <Support />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/account'
                exact
                component={() => (
                  <Layout>
                    <Account />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/property-details/:propertyId/map'
                exact
                component={() => (
                  <Layout>
                    <PropertyMapExplorer />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/property-details/:propertyId/image'
                exact
                component={() => (
                  <Layout>
                    <PropertyMapUpload />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/change-password'
                exact
                component={() => (
                  <Layout>
                    <ChangePassword />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/sprayplan-report/:propertyId'
                exact
                component={() => (
                  <Layout>
                    <SprayPlanReport />
                  </Layout>
                )}
              />
              <ApmRoute
                path='/hazard-report/:propertyId/:productionId'
                exact
                component={() => (
                  <Layout>
                    <HazardReport />
                  </Layout>
                )}
              />
            </>
          ) : (
            <>{!userStore.loading && <Redirect to={'/login'} />}</>
          )}

          {/* Default to landing  */}
          <ApmRoute
            path='/'
            component={() => (
              <Layout>
                <PageDoesNotExistAlert />
                <Landing />
              </Layout>
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

export default observer(App);
