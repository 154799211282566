import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';

const Support = (): JSX.Element => {
  return (
    <Container className='px-5 py-3'>
      <h2 className='text-center'>Support</h2>
    </Container>
  );
};

export default observer(Support);
